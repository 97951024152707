import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { t } from "i18next";
import { FaCircleDot } from "react-icons/fa6";
import { CloseOutlined as IoMdClose } from "@ant-design/icons";
import { connect } from "react-redux";

import ModalComponent from "../../../common/Modal/modal";
import Notify from "../../../../../components/common/Notify/notify";
import { reportSubscriptionCreate } from "../../../../redux/slice";
import {
  accessReportsImage,
  checkMarkIcon
} from "../../../../assets/images";
import "./phoneReportModal.scss";
import { formatNumberByCountry } from "../../../../../utils/commonUtils";

const PhoneReportModal = ({
  isOpen,
  current,
  setPhoneReportModalOpen,
  number,
  callReportSubscriptionCreate,
  countryCode,
  setIsOpenAnimation,
  fetchPhoneReportDetails,
  phoneReportDetails,
  pricingTitleList,
  setPhoneReportModalShow,
}) => {
  const [showError, setShowError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const price = pricingTitleList?.subscriptions.phone_report;
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const phoneReportId =
    phoneReportDetails?.getPhoneReportDetailsData?.data.phoneReport?._id ||
    searchNumber.id;

  const onClose = () => {
    setIsChecked(false);
    setPhoneReportModalOpen(false);
    setPhoneReportModalShow(false);
  };

  const handleOnClickOfSeeReport = async () => {
    if (isLoading) return;
    setIsLoading(true);

    if (!isChecked) {
      setShowError(true);
      setIsLoading(false);
      return;
    }

    try {
      onClose();
      setIsOpenAnimation(true);
      const data = { phone_report_id: phoneReportId };
      const result = await callReportSubscriptionCreate(data);

      if (result.type === "reportSubscriptionCreate/fulfilled") {
        await new Promise((resolve) => setTimeout(resolve, 10000));
        await fetchPhoneReportDetails();
        setIsOpenAnimation(false);
        Notify("success", result.payload.message, "");
        const formattedCountryCode =
          countryCode === 1 ? "+1" : countryCode.replace(" ", "");
        navigate(
          `/${lang}/access-report?D=${formattedCountryCode}&n=${searchNumber.n}&id=${phoneReportId}`
        );
      } else {
        Notify("error", result.error.message || "An error occurred", "");
        setIsOpenAnimation(false);
      }
    } catch (error) {
      console.warn("error", JSON.stringify(error));
    } finally {
      setIsLoading(false);
      setIsOpenAnimation(false);
    }
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={false}
      className="location__modal_wrapper"
    >
      {/* Modal 1 */}
      {current === "modal1" && (
        <div className="lp__report_popup">
          {/* <div className="wpb_table_element">
            <table>
              <thead>
                <tr>
                  <th>PR_NUMBER</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a href="tel:(819) 674-0000">(819) 674-0000</a>
                  </td>
                  <td>Search in Progress</td>
                </tr>
              </tbody>
            </table>
          </div> */}
          <div className="wpb_popup_body">
            <div className="popup_body_description">
              <p>
                {t("PR_SYSTEM_TRACKING")}
                <a href={`tel:${number}`}>{number}</a>.<br />
                {t("PR_OWNER_OF_THIS_NUMBER")}
              </p>
            </div>
            <div className="popup_body_image">
              <img src={accessReportsImage} width={420} height={200} alt="Access Reports Image" />
            </div>
            <div className="popup_body_listing">
              <div className="popup__title">
                <h2>{t("PR_BACKGROUND_AND_REPORT")}:</h2>
              </div>
              {/* <div className="body__list">
                <ul>
                  <li>
                    <FaCircleDot /> Accidents
                  </li>
                  <li>
                    <FaCircleDot /> Bankruptcies
                  </li>
                  <li>
                    <FaCircleDot /> Civil Court Records
                  </li>
                  <li>
                    <FaCircleDot /> Corporate Affiliations
                  </li>
                  <li>
                    <FaCircleDot /> Death Search
                  </li>
                  <li>
                    <FaCircleDot /> Foreclosures
                  </li>
                  <li>
                    <FaCircleDot /> Liens & Judgements
                  </li>
                  <li>
                    <FaCircleDot /> Marriage & Divorce Search
                  </li>
                  <li>
                    <FaCircleDot /> People at Work
                  </li>
                </ul>
              </div> */}
              <div class="body__list">
                <ul>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    {t("PR_ACCIDENTS")}
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    {t("PR_CORPORATE_AFFILIATIONS")}
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    {t("PR_REPORT_POPUP_TEXT_7")}
                  </li>
                </ul>
                <ul>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    {t("PR_CORPORATE_AFFILIATIONS")}
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    {t("PR_DEATH_SEARCH")}
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    {t("PR_MARRIAGE_AND_DIVORCE")}
                  </li>
                </ul>
                <ul>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    {t("PR_CIVIL_COURT_RECORDS")}
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    {t("PR_FORECLOSURES")}
                  </li>
                  <li>
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 512 512"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-352a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
                    </svg>
                    {t("PR_PEOPLE_AT_WORK")}
                  </li>
                </ul>
              </div>
              <div className="report__btn" onClick={handleOnClickOfSeeReport}>
                <button className="popup_btn__report" disable={isLoading}>
                  {t("PR_SEE_REPORT")}
                </button>
              </div>
              <div
                className="thanks_btn"
                onClick={() => setPhoneReportModalOpen(false)}
              >
                <a href="#">{t("PR_DENIED_BUTTON")}</a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal 2 */}
      {current === "modal2" && (
        <div className="lp__report_popup">
          <div className="wpb_popup_body">
            <div className="popup_body_description">
              <p>
                {t("PR_SYSTEM_TRACKING")}
                <a href={`tel:${number}`}>{number}</a>.<br />
                {t("PR_OWNER_OF_THIS_NUMBER")}
              </p>
            </div>
            <div className="popup_body_image">
              <img src={accessReportsImage} width={420} height={200} alt="Access Reports Image" />
            </div>
            <div className="popup_body_listing">
              <div className="popup__title">
                <h2>{t("PR_BACKGROUND_AND_REPORT")}</h2>
              </div>
              <div class="body__list">
                <ul>
                  <li>
                    <FaCircleDot /> {t("PR_ACCIDENTS")}
                  </li>
                  <li>
                    <FaCircleDot /> {t("PR_CORPORATE_AFFILIATIONS")}
                  </li>
                  <li>
                    <FaCircleDot /> {t("PR_REPORT_POPUP_TEXT_7")}
                  </li>
                </ul>
                <ul>
                  <li>
                    <FaCircleDot /> {t("PR_CORPORATE_AFFILIATIONS")}
                  </li>
                  <li>
                    <FaCircleDot /> {t("PR_DEATH_SEARCH")}
                  </li>
                  <li>
                    <FaCircleDot /> {t("PR_MARRIAGE_AND_DIVORCE")}
                  </li>
                </ul>
                <ul>
                  <li>
                    <FaCircleDot /> {t("PR_CIVIL_COURT_RECORDS")}
                  </li>
                  <li>
                    <FaCircleDot /> {t("PR_FORECLOSURES")}
                  </li>
                  <li>
                    <FaCircleDot /> {t("PR_PEOPLE_AT_WORK")}
                  </li>
                </ul>
              </div>
              <div className="popup_radio_btn">
                <input type="radio" id="popup_radio" name="Policy_terms" />
                <label htmlFor="popup_radio">{t("PR_TRANSACTION_TERM")}</label>
              </div>
              <div className="report__btn" onClick={handleOnClickOfSeeReport}>
                <button className="popup_btn__report" disable={isLoading}>
                  {t("PR_SEE_REPORT")}
                </button>
              </div>
              <div
                className="thanks_btn"
                onClick={() => setPhoneReportModalOpen(false)}
              >
                <a href="#">{t("PR_DENIED_BUTTON")}</a>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal 3 */}
      {current === "modal3" && (
        <div className="lp__report_popup">
          <div className="wpb_popup_body">
            <div className="number__location_wrapper">
              <p>
                {t("LOCATING")} <p>{formatNumberByCountry(number)}</p> ...
              </p>
            </div>
            <div className="popup_body_description">
              <p>
                {t("PR_ACCESS_REPORT_MODAL_TITLE_1")}{" "}
                <p className="mobile_number">{formatNumberByCountry(number)}</p>{" "}
                {t("PR_ACCESS_REPORT_POPUP_TITLE_2")}
              </p>
              {/* <p>{t("PR_REPORT_POPUP_DESCRIPTION_1")}</p> */}
            </div>
            <div className="popup_body_image">
              <img src={accessReportsImage} width={420} height={200} alt="Access Reports Image" />
              {/* <p className="dynemic__number_wrap">{number}</p> */}
            </div>
            <div className="popup_body_listing">
              <div className="popup__title">
                <h2>{t("PR_ACCESS_REPORT_TITLE_3")}</h2>
              </div>
              <div className="report--listing">
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_OWNER_INFORMATION")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_SOCIAL_MEDIA_PROFILES")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_PHOTO_VIDEO")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_DATING_PROFILES")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_ADDRESS_HISTORY")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_ONLINE_ACTIVITY")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_ASSOCIATES")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_EMAIL_ADDRESS")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_PERSONNAL_DATA_LEAKS")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_COMPANIES_JOBS")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span>{t("PR_REPORT_POPUP_TEXT_6")}</span>
                </div>
                <div className="report--listing-box">
                    <img src={checkMarkIcon} width={20} height={20} alt="Access Reports Image" />
                    <span className="report__listing_blod">{t("PR_MUCH_MORE")}</span>
                </div>
              </div>
              <div className="report-btn-wrap">
                <button
                  onClick={() => handleOnClickOfSeeReport()}
                  className={"access__btn_wrap"}
                  disable={isLoading}
                >
                  <p>{t("PR_ACCESS_VIEW_REPORT")}</p>
                </button>
              </div>
              <div className="thanks_btn">
                <a onClick={onClose} href="#">
                  {t("PR_DENIED_BUTTON")}
                </a>
              </div>
              <div className="popup_checkbox popup_radio_btn">
                {showError && (
                  <p className="invalid-number-error">
                    {t("PR_REPORT_POPUP_ERROR")}
                    <IoMdClose onClick={() => setShowError(false)} />{" "}
                  </p>
                )}
                <input
                  type="checkbox"
                  id="popup_radio"
                  name="Policy_terms"
                  checked={isChecked}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setIsChecked(checked);
                    setShowError(!checked);
                  }}
                  className={showError ? "checkbox-error" : ""}
                />
                <label htmlFor="popup_radio">
                  {t("PR_TERM", {
                    price: price?.symbol + price?.amount,
                  })}
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </ModalComponent>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails: state.getPhoneReportDetails,
    pricingTitleList: state.pricingTitleList?.getPhoneReportDetailsData?.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callReportSubscriptionCreate: (data) =>
      dispatch(reportSubscriptionCreate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneReportModal);