import {t} from "i18next";

export const getSubscriptionLookup = (subscription) => {
  const {status, remaining_report, sub_status, name, is_trial_canceled} =
    subscription;
  return {
    isStatusCanceled: status === "canceled",
    isLimitExceeded: remaining_report === 0,
    isStatusActive: status === "active",
    isStatusTrialing: status === "trialing",
    hasSubStatus: !!sub_status,
    isPendingUnsubscribe: sub_status === "pending_unsubscribe",
    isFullReportUnlock: name === "Premium Reports",
    isTrialCanceled: is_trial_canceled
  };
};
export const getSubscriptionStatus = (subscription) => {
  const {isStatusCanceled, isStatusActive, isStatusTrialing} =
    getSubscriptionLookup(subscription);
  if (isStatusCanceled) {
    return {
      statusText: t("CANCELED"),
      statusClass: "red-bg"
    };
  }
  if (isStatusActive || isStatusTrialing) {
    return {
      statusText: t("ACTIVE"),
      statusClass: "green-bg"
    };
  }
  return {statusText: "N/A", statusClass: "default-status"};
};

export const getSubscriptionText = (subscription) => {
  const {
    isStatusCanceled,
    isStatusActive,
    isStatusTrialing,
    isPendingUnsubscribe,
    isFullReportUnlock,
    hasSubStatus,
    isLimitExceeded
  } = getSubscriptionLookup(subscription);
  if (isStatusCanceled) {
    return t("REACTIVATE_SUBSCRIPTION");
  } else if (isFullReportUnlock && isLimitExceeded) {
    return t("PR_ADD_MORE_REPORT");
  } else if ((isStatusActive || isStatusTrialing) && !hasSubStatus) {
    return t("CANCEL_SUBSCRIPTION");
  } else if ((isStatusActive || isStatusTrialing) && isPendingUnsubscribe) {
    return t("REACTIVATE_SUBSCRIPTION");
  } else {
    return null;
  }
};

export const getTooltipContent = (subscriptionName) => {
  switch (subscriptionName) {
    case "PDF Download":
      return (
        <ul
          style={{margin: 0, paddingLeft: "1em"}}
          className="subscription_tooltip_wrapper"
        >
          <li>{t("PR_SUBSCRIPTION_TOOLTIP_PDF")}</li>
        </ul>
      );
    case "Phone Report":
      return (
        <ul
          style={{margin: 0, paddingLeft: "1em"}}
          className="subscription_tooltip_wrapper"
        >
          <li>{t("PR_SUBSCRIPTION_TOOLTIP_PHONE_REPORT_1")}</li>
          <li>{t("PR_SUBSCRIPTION_TOOLTIP_PHONE_REPORT_2")}</li>
        </ul>
      );
    case "Phone Tracking":
      return (
        <ul
          style={{margin: 0, paddingLeft: "1em"}}
          className="subscription_tooltip_wrapper"
        >
          <li>{t("PR_SUBSCRIPTION_TOOLTIP_PHONE_TRACKING")}</li>
        </ul>
      );
    case "Premium Reports":
      return (
        <ul
          style={{margin: 0, paddingLeft: "1em"}}
          className="subscription_tooltip_wrapper"
        >
          <li>{t("PR_SUBSCRIPTION_TOOLTIP_PREMIUM_REPORT_1")}</li>
          <li>{t("PR_SUBSCRIPTION_TOOLTIP_PREMIUM_REPORT_2")}</li>
        </ul>
      );
    default:
      return null;
  }
};
