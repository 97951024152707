import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { t } from "i18next";
import "./phoneNumberAttributeDetails.scss";
import ModalComponent from "../../../common/Modal/modal";
import Notify from "../../../../../components/common/Notify/notify";
import { addAdditionalInfo } from "../../../../redux/slice/addAdditionalInfoSlice";
import countryList from "react-select-country-list";
import { Select } from "antd";

const PhoneNumberAttributeDetails = ({
  isOpen,
  onClose,
  handlePayment,
  setIsLoading,
  // API and state
  phoneReportDetails,
  callAddAdditionalInfo,
}) => {
  const { phoneReport } = phoneReportDetails || {};
  const countryOptions = countryList().getData();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    username: "",
    country: "",
  });
  useEffect(() => {
    if (phoneReport?.additional_details) {
      const { country, first_name, last_name, email_address, username } =
        phoneReport?.additional_details;
      setFormData({
        first_name: first_name,
        last_name: last_name,
        email_address: email_address,
        username: username,
        country: country,
      });
    }
  }, [phoneReport]);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (value, key = null) => {
    const fieldKey = key || value.target.id;
    const fieldValue = key ? value : value.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldKey]: fieldValue,
    }));
  };

  const handleSubmit = async (cancel) => {
    const { email_address } = formData;
    // if (![first_name, last_name, email_address, username, country].some((value) => value.trim())) {
    //   return;
    // }
    if (!cancel) {
      if (email_address && !emailRegex.test(email_address)) {
        return Notify("error", t("ENTER_VALID_EMAIL"), "");
      }
    }
    onClose();
    setIsLoading(true);
    try {
      const payload = {
        phone_report_id: phoneReport?._id,
        ...Object.fromEntries(
          Object.entries(formData).filter(([, value]) => value.trim())
        ),
      };
      await callAddAdditionalInfo(payload);
      setFormData({
        first_name: "",
        last_name: "",
        email_address: "",
        username: "",
        country: "",
      });
      await handlePayment();
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={() => handleSubmit(true)}
      closable={true}
      className={"multiples_popup__wrap_close_button Enhance__PopupWrapper"}
      maskClosable={false}
    >
      <div className="accuracy_popup">
        <div className="accuracy_popup_info">
          <div className="accuracy_popup_title">
            <h2>{t("PR_ACCURACY_POPUP_TITLE")}</h2>
            <p>{t("PR_ACCURACY_POPUP_DESCRIPTION")}</p>
          </div>
          <div className="accuracy_popup_field">
            <form action="/">
              <div className="accuracy_container">
                {/* First Name */}
                <div className="accuracy_field-account">
                  <label className="lbl_wpb_wrapper" htmlFor="first_name">
                    {t("SETTINGS_FIRST_NAME_LABEL")}
                  </label>
                  <div className="ant-input-optional">
                    <input
                      className="ant-input-prefix"
                      id="first_name"
                      type="text"
                      inputMode="text"
                      value={formData.first_name}
                      onChange={handleChange}
                      required
                      placeholder={`John (${t("OPTIONAL")})`}
                    />
                  </div>
                </div>
                {/* Last Name */}
                <div className="accuracy_field-account">
                  <label className="lbl_wpb_wrapper" htmlFor="last_name">
                    {t("SETTINGS_LAST_NAME_LABEL")}
                  </label>
                  <div className="ant-input-optional">
                    <input
                      className="ant-input-prefix"
                      id="last_name"
                      type="text"
                      inputMode="text"
                      value={formData.last_name}
                      onChange={handleChange}
                      required
                      placeholder={`Carlson (${t("OPTIONAL")})`}
                    />
                  </div>
                </div>
                {/* Email Address */}
                <div className="accuracy_field-account">
                  <label className="lbl_wpb_wrapper" htmlFor="email_address">
                    {t("SIGNUP_EMAIL_LABEL")}
                  </label>
                  <div className="ant-input-optional">
                    <input
                      className="ant-input-prefix"
                      id="email_address"
                      type="text"
                      inputMode="text"
                      value={formData.email_address}
                      onChange={handleChange}
                      required
                      placeholder={`email@address.com (${t("OPTIONAL")})`}
                    />
                  </div>
                </div>
                {/* Username */}
                <div className="accuracy_field-account">
                  <label className="lbl_wpb_wrapper" htmlFor="username">
                    {t("PR_ONLINE_USERNAME")}
                  </label>
                  <div className="ant-input-optional">
                    <input
                      className="ant-input-prefix"
                      id="username"
                      type="text"
                      inputMode="text"
                      value={formData.username}
                      onChange={handleChange}
                      required
                      placeholder={`@JohnCarlson007 (${t("OPTIONAL")})`}
                    />
                  </div>
                </div>
                {/* Country */}
                <div className="accuracy_field-account">
                  <label className="lbl_wpb_wrapper" htmlFor="country">
                    {t("COUNTRY")}
                  </label>
                  <div className="ant-input-optional">
                    <Select
                      placeholder={`United States (${t("OPTIONAL")})`}
                      defaultValue={formData.country || undefined}
                      className="ant-input-prefix accuracy_field_country"
                      name="randomName"
                      id="randomId"
                      onChange={(value) => handleChange(value, "country")}
                      value={formData.country || undefined}
                      options={countryOptions}
                      showSearch
                      autoComplete="new-password"
                      filterOption={(input, option) => {
                        if (option && option.label) {
                          const optionLabel = option.label
                            .toString()
                            .toLowerCase();
                          const inputValue = input.toLowerCase();
                          return optionLabel.indexOf(inputValue) !== -1;
                        }
                        return false;
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="accuracy_button">
                <button
                  className="accuracy__account"
                  type="button"
                  onClick={handleSubmit}
                >
                  {t("LOGIN_SUBMIT")}
                </button>
                <div className="enhance__BtnContent">
                  <a onClick={() => handleSubmit(true)}>{t("PR_NO_THANKS")}</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callAddAdditionalInfo: (data) => dispatch(addAdditionalInfo(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneNumberAttributeDetails);
