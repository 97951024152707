import React, { useEffect, useState } from "react";
import PhoneGpsComponent from "./phoneGpsComponent";
import { useLoadScript } from "@react-google-maps/api";
import { googleAPIKey } from "../../../../environment";
import { connect, useSelector } from "react-redux";
import { subscriptionStatus } from "../../../redux/slice/subscriptionStatusSlice";
import Notify from "../../../../components/common/Notify/notify";
import MultiActionModal from "../../partials/Modals/multiActionModal";
import { checkAdminLogin } from "../../../utils/commonUtils";
const PhoneGps = ({
  number,
  setOpenLocateModal,
  phoneReportDetails,
  callCheckSubscriptionStatus,
  isAccessReportPage = false
}) => {
  const locations = phoneReportDetails?.phoneReport?.locations;
  const defaultLocation = useSelector(
    (state) => state?.sendDetailsData?.getSendDetailsData?.data || {}
  );
  const [infoModalAddress, setInfoModalAddress] = useState(null);
  const [actionModal, setActionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [latLong, setLatLong] = useState([
    {
      latlng: {lat: null, lng: null},
      address: ""
    }
  ]);
  const [center, setCenter] = useState({
    lat: defaultLocation?.latitude || 0,
    lng: defaultLocation?.longitude || 0
  });

  useEffect(() => {
    if (defaultLocation?.latitude && defaultLocation?.longitude) {
      setCenter({
        lat: defaultLocation.latitude,
        lng: defaultLocation.longitude
      });
    }
  }, [defaultLocation]);
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: googleAPIKey
  });

  useEffect(() => {
    if (locations) {
      let defaultLatLong = locations.find((item) => {
        return item.status === true;
      });
      if (defaultLatLong) {
        setLatLong([
          {
            latlng: {
              lat: defaultLatLong.geo.lat,
              lng: defaultLatLong.geo.long
            },
            address: defaultLatLong.geo.formatted
          }
        ]);
        setCenter({
          lat: defaultLatLong.geo.lat,
          lng: defaultLatLong.geo.long
        });
      }
    }
  }, [locations]);

  const handleChangeMap = (lat, lng, address) => {
    setInfoModalAddress(null);
    setLatLong([
      ...latLong,
      {
        latlng: {lat: lat, lng: lng},
        address: address
      }
    ]);
    setCenter({
      lat: lat,
      lng: lng
    });
  };
  const handleCheckSubscription = async () => {
    if (checkAdminLogin()) {
      return;
    }
    const res = await callCheckSubscriptionStatus("tracking");
    if (res.type === "subscriptionStatus/fulfilled") {
      const {subscription, canceled} = res.payload.data || {};
      if (!subscription && canceled) {
        setActionModal(true);
      } else {
        setActionModal(false);
        setOpenLocateModal(true);
      }
    } else {
      Notify("error", res.error.message, "");
    }
  };
  return (
    <>
      <MultiActionModal
        actionModal={actionModal}
        setActionModal={setActionModal}
        modalType={"renew_subscription"}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        subscription={"tracking"}
      />
      <PhoneGpsComponent
        setIsOpen={setOpenLocateModal}
        number={number}
        phoneReportDetails={phoneReportDetails}
        center={center}
        latLong={latLong}
        infoModalAddress={infoModalAddress}
        isLoaded={isLoaded}
        setInfoModalAddress={setInfoModalAddress}
        handleChangeMap={handleChangeMap}
        handleCheckSubscription={handleCheckSubscription}
        isAccessReportPage={isAccessReportPage}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
  };
};

export default connect(null, mapDispatchToProps)(PhoneGps);
