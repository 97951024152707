import React, { useEffect, useRef, useState } from "react";
import "./accessReport.scss";
import { PhoneGps } from "../../components/common";
import {
  accessReportsImage,
  checkMarkIcon,
  newHeaderSearchIcon,
  unlockedPhonereportIcon,
} from "../../assets/images";
import { PhoneReportModal } from "../../components/partials";
import StepTutorialModal from "../../components/partials/Modals/stepTutorial/stepTutorial";
import { Cookies } from "react-cookie";
import { parsePhoneNumber } from "libphonenumber-js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PolyAnimation from "../polyAnimation/polyAnimation";
import ModalComponent from "../../components/common/Modal/modal";
import Notify from "../../../components/common/Notify/notify";
import MultiActionModal from "../../components/partials/Modals/multiActionModal";
import { checkAdminLogin } from "../../utils/commonUtils";
import { formatNumberByCountry } from "../../../utils/commonUtils";

const AccessReportComponent = ({
  senderNumber,
  setOpenLocateModal,
  phoneReportModalShow,
  countryCode,
  fetchPhoneReportDetails,
  phoneReportDetails,
  phoneReportId,
  callCheckSubscriptionStatus,
  setPhoneReportModalShow,
}) => {
  const [formateNumber, setFormateNumber] = useState(0);
  const [stepModalOpen, setStepModalOpen] = useState(false);
  const [isOpenAnimation, setIsOpenAnimation] = useState(false);
  const [phoneReportModalOpen, setPhoneReportModalOpen] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const cookies = new Cookies();
  const stepCheck = cookies.get("step");
  const lang = cookies.get("lang");

  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (senderNumber) {
      let fullNumber = "+" + senderNumber.replace("+", "");
      if (fullNumber.length > 8) {
        const phoneNumber = parsePhoneNumber(fullNumber);
        if (phoneNumber) {
          const updatedNumber = phoneNumber.formatInternational()
          setFormateNumber(formatNumberByCountry(updatedNumber));
        }
      }
    }
  }, [senderNumber]);

  const modalTimeoutRef = useRef(null);
  useEffect(() => {
    if (phoneReportModalShow && stepCheck === "true") {
      if (!phoneReportModalOpen) {
        modalTimeoutRef.current = setTimeout(() => {
          setPhoneReportModalOpen(true);
        }, 10000);
      } else {
        console.log(modalTimeoutRef.current, phoneReportModalOpen, "__");
        clearTimeout(modalTimeoutRef.current);
        modalTimeoutRef.current = null; // Clear the ref value to avoid stale references
      }
    }

    return () => {
      // Cleanup timeout on unmount or dependencies change
      if (modalTimeoutRef.current) {
        clearTimeout(modalTimeoutRef.current);
      }
    };
  }, [phoneReportModalShow, phoneReportModalOpen, stepCheck]);

  useEffect(() => {
    if (stepCheck !== "true" && !phoneReportId) {
      setStepModalOpen(true);
    } else {
      setStepModalOpen(false);
    }
    // eslint-disable-next-line
  }, []);
  const onCloseSteps = () => {
    cookies.set("step", "true");
    setStepModalOpen(false);
    setOpenLocateModal(true);
  };

  const handleCheckSubscription = async () => {
    if (checkAdminLogin()) {
      return;
    }
    const res = await callCheckSubscriptionStatus("phone_report");
    if (res.type === "subscriptionStatus/fulfilled") {
      const { subscription, canceled } = res.payload.data || {};
      if (!subscription && canceled) {
        setActionModal(true);
      } else {
        setActionModal(false);
        setPhoneReportModalOpen(true);
      }
    } else {
      Notify("error", res.error.message, "");
    }
  };
  // For redirect user to premium page while they activate their phone report subscription
  const onRedirection = (isRedirect) => {
    if (isRedirect) {
      fetchPhoneReportDetails(phoneReportDetails?.phoneReport?._id);
    }
  };
  return (
    <>
      {phoneReportModalOpen && (
        <PhoneReportModal
          isOpen={phoneReportModalOpen}
          current={"modal3"}
          setPhoneReportModalOpen={setPhoneReportModalOpen}
          number={formateNumber}
          countryCode={countryCode}
          setIsOpenAnimation={setIsOpenAnimation}
          fetchPhoneReportDetails={fetchPhoneReportDetails}
          setPhoneReportModalShow={setPhoneReportModalShow}
        />
      )}
      <MultiActionModal
        actionModal={actionModal}
        setActionModal={setActionModal}
        modalType={"renew_subscription"}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        subscription={"phone_report"}
        isRedirection={onRedirection}
      />
      <StepTutorialModal isOpen={stepModalOpen} onClose={onCloseSteps} />
      <ModalComponent
        isOpen={isOpenAnimation}
        onClose={() => setIsOpenAnimation(false)}
        closable={false}
        className="polly_animation_modal"
      >
        <PolyAnimation />
      </ModalComponent>
      <div className="main_root">
        {/* <PhoneReportMenu /> */}
        <div className="ds--access-report premium_sec_wrap">
          <div className="ds__AcessMenu">
            <div className="main_container">
              <div className="menu__BoxWrapper">
                <div className="menu_buttonWrapper">
                  <ul>
                    <li>
                      {" "}
                      <a
                        className="menu__BtnPrimary"
                        href="javascript:void(0)"
                        onClick={() => handleCheckSubscription()}
                      >
                        <img
                          src={unlockedPhonereportIcon}
                          alt=""
                          width="21"
                          height="21"
                        />{" "}
                        <span>{t("PR_UNLOCK")}</span>
                        {t("PR_PHONE_REPORT")}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        className="menu__BtnPrimary"
                        href="javascript:void(0)"
                        onClick={() => navigate(`/${lang}/dashboard`)}
                      >
                        <img
                          src={newHeaderSearchIcon}
                          alt=""
                          width="21"
                          height="21"
                        />
                        {t("PR_NEW_SEARCH_HEADER")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="main_container">
            <div className="premium_box_wrap">
              <div className={`premium-section-width`}>
                <PhoneGps
                  number={formateNumber}
                  setOpenLocateModal={setOpenLocateModal}
                  phoneReportDetails={phoneReportDetails}
                  isAccessReportPage
                />
                <div className="ds--report-wrap white-bg-wrap">
                  <div className="main_title">
                    <h2>
                      {t("PR_LEARN_MORE_WITH")}{" "}
                      <span>{t("PR_ACCESS_PHONE_REPORTS")}</span>
                    </h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t("PR_PHONE_REPORTS_DESC", {
                          number: formateNumber,
                        }),
                      }}
                    ></p>
                  </div>
                  <div className="report--img">
                    <img src={accessReportsImage} width={420} height={200} alt="Access Reports Image" />
                  </div>
                  <div className="report-btn-wrap">
                    <button
                      className={"access__btn_wrap"}
                      onClick={() => handleCheckSubscription()}
                    >
                      <p>{t("PR_ACCESS_VIEW_REPORT")}</p>
                    </button>
                  </div>
                  <div className="report--listing">
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_OWNER_INFORMATION")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_SOCIAL_MEDIA_PROFILES")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_PHOTO_VIDEO")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_DATING_PROFILES")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_ADDRESS_HISTORY")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_ONLINE_ACTIVITY")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_ASSOCIATES")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_EMAIL_ADDRESS")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_PERSONNAL_DATA_LEAKS")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_COMPANIES_JOBS")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span>{t("PR_REPORT_POPUP_TEXT_6")}</span>
                    </div>
                    <div className="report--listing-box">
                      <img src={checkMarkIcon} width={20} height={20} alt="Check Mark Icon" />
                      <span className="report__listing_blod">
                        {t("PR_MUCH_MORE")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessReportComponent;
