import {
  subExpiredIcon,
  unlockReportsIcon,
} from "../../../../assets/images";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import TransactionDeclinedModal from "../../../../pages/transactionDeclined/transactionDeclined";
import ModalComponent from "../../../common/Modal/modal";
import Loader from "../../../common/loader/loader";
import { openReport } from "../../../../redux/slice/openReportSlice";
import { subscriptionStatus } from "../../../../redux/slice/subscriptionStatusSlice";
import { renewSubscription } from "../../../../redux/slice/renewSubscriptionSlice";
import { generatePayment } from "../../../../redux/slice/generatePaymentSlice";
import { retryPayment } from "../../../../redux/slice/retryPaymentSlice";
import { updatePaymentMethod } from "../../../../redux/slice/updatePaymentMethodSlice";
import { getPhoneReportDetails } from "../../../../redux/slice";
import Notify from "../../../../../components/common/Notify/notify";
import { confirmPayment } from "../../../../utils/commonUtils";
import "./multiActionModal.scss";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { createSubscription } from "../../../../redux/slice/createSubscriptionSlice";
import { t } from "i18next";
import PhoneNumberAttributeDetails from "../phoneNumberAttributeDetails/phoneNumberAttributeDetails";
import { ProcessModalContext } from "../../../../context/processModalProvider";
import { additionalInfoStatus } from "../../../../redux/slice/additionalInfoStatusSlice";
import moment from "moment-timezone";
import {CONSTANTS} from "../../../../utils/constant";
import AddMoreReport from "./addMoreReport";
import { formatNumberByCountry } from "../../../../../utils/commonUtils";

const MultiActionModal = (props) => {
  const {
    modalType,
    actionModal,
    setActionModal,
    phoneReportDetails,
    isLoading,
    setIsLoading,
    subscription,
    isRedirection = false,
    //API
    callOpenReport,
    callRenewSubscription,
    callGeneratePayment,
    callRetryPayment,
    callUpdatePaymentMethod,
    callCreateSubscription,
    callGetPhoneReportDetails,
    pricingTitleList,
    callAdditionalInfoStatus,
    subscriptionStatus
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [retryModal, setRetryModal] = useState(false);
  const [stripeError, setStripeError] = useState("");
  const [infoModal, setInfoModal] = useState(false);
  const {phoneReport} = phoneReportDetails || {};
  const {openProcessModal} = useContext(ProcessModalContext);
  const {phone_number, phone_number_prefix} =
    phoneReportDetails?.phoneReport || {};
  const formatedNumber = phone_number_prefix + phone_number;
  const price =
    pricingTitleList?.subscriptions?.[CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT]
      ?.amount || CONSTANTS.WRONG_PRICING.PREMIUM_REPORT;
  const priceSymbol =
    pricingTitleList?.subscriptions?.[CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT]
      ?.symbol || "$";
  const exdDateFromSubs =
    subscriptionStatus.subscriptionStatus &&
    subscriptionStatus.subscriptionStatus?.data?.endDate
      ? subscriptionStatus.subscriptionStatus?.data?.endDate
      : subscriptionStatus.subscriptionStatus?.data?.trial_end_date;
  const endDate = moment(
    subscriptionStatus.subscriptionStatus && exdDateFromSubs
  ).format("MMMM DD, YYYY");
  const retryPrice =
    subscription === "tracking" || subscription === "phone_report"
      ? subscriptionStatus?.subscriptionStatus?.data?.amount
      : price;

  const handleOpenReport = async () => {
    setActionModal(false);
    setIsLoading(true);
    try {
      const data = {
        phone_report_id: phoneReport?._id
      };
      const res = await callOpenReport(data);
      if (res.type === "openReport/fulfilled") {
        if (res.payload.data?.eventObj?.events) {
          openProcessModal(phoneReport._id);
        } else {
          Notify("success", res.payload.message, "");
          await callGetPhoneReportDetails(phoneReport._id);
        }
      } else {
        Notify("error", res.error.message, "");
      }
    } catch (error) {
      Notify("error", error.message || "An error occurred", "");
    } finally {
      setIsLoading(false);
    }
  };

  const openReport = async () => {
    const isAnyFieldEmpty = Object.values(
      phoneReport?.additional_details || {}
    ).some((value) => !value?.trim());
    if (isAnyFieldEmpty) {
      setActionModal(false);
      let payload = {
        phone_report_id: phoneReport?._id,
        apiPayload: {
          phone_report_id: phoneReport?._id
        },
        apiName: "open_report"
      };
      callAdditionalInfoStatus(payload);
      setInfoModal(true);
    } else {
      await handleOpenReport();
    }
  };
  // handleFlow
  const handleFlow = async () => {
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    setActionModal(false);

    const payload = {
      price_title: subscription,
      type: "subscription"
    };

    try {
      const res = await callGeneratePayment(payload);
      if (res.type === "generatePayment/fulfilled") {
        const {client_secret, payment_method, subscription_id} =
          res.payload.data || {};
        const {error} = await confirmPayment(
          stripe,
          client_secret,
          payment_method
        );
        if (error) {
          setRetryModal(true);
        } else {
          const payload =
            modalType === "more_report_subscription"
              ? {phone_report_id: phoneReport?._id, subscription_id}
              : {subscription_id, subscription_name: subscription};
          const res =
            modalType === "more_report_subscription"
              ? await callCreateSubscription(payload)
              : await callRenewSubscription(payload);

          if (res?.type?.endsWith("fulfilled")) {
            // For redirect user to premium page while they activate their phone report subscription
            if (isRedirection) {
              isRedirection(true);
            }
            Notify("success", res.payload.message, "");
          } else {
            Notify("error", res?.error?.message, "");
          }
        }
      } else {
        Notify("error", res.error.message, "");
      }
    } catch (error) {
      console.error("Error occurred from handleFrom", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handled retry payment request
  const onRetry = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    setActionModal(false);
    const cardNumberElement = elements.getElement(CardNumberElement);

    try {
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement
      });
      if (error) {
        setStripeError(error.message);
        return;
      }

      setRetryModal(false);
      const data = {
        price_title: subscription,
        type: "subscription"
      };

      const res = await callRetryPayment(data);
      if (res.type === "retryPayment/fulfilled") {
        const {client_secret: clientSecret, subscription_id} =
          res.payload.data || {};
        const {error} = await confirmPayment(
          stripe,
          clientSecret,
          paymentMethod.id
        );

        if (error) {
          elements.getElement(CardNumberElement).clear();
          elements.getElement(CardExpiryElement).clear();
          elements.getElement(CardCvcElement).clear();
          setRetryModal(false);
          setStripeError("");
          setTimeout(() => {
            setRetryModal(true);
          }, 500);
        } else {
          const payload =
            modalType === "more_report_subscription"
              ? {phone_report_id: phoneReport?._id, subscription_id}
              : {subscription_id, subscription_name: subscription};
          const res =
            modalType === "more_report_subscription"
              ? await callCreateSubscription(payload)
              : await callRenewSubscription(payload);

          if (res?.type?.endsWith("fulfilled")) {
            // For redirect user to premium page while they activate their phone report subscription
            if (isRedirection) {
              isRedirection(true);
            }
            Notify("success", res.payload.message, "");
          } else {
            Notify("error", res?.error?.message, "");
          }
          callUpdatePaymentMethod(paymentMethod.id);
        }
      } else {
        Notify("error", res.error.message, "");
      }
    } catch (error) {
      console.error("Error occurred from retry", error);
    } finally {
      setIsLoading(false);
    }
  };

  const orderDetails = {
    orderName:
      subscription === "tracking"
        ? t("PR_REPORT_PHONE_TRACKING")
        : subscription === "phone_report"
        ? t("PR_PHONE_REPORT")
        : subscription === CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
        ? "Premium Reports"
        : null,
    orderPrice: priceSymbol + retryPrice
  };
  return (
    <>
      {isLoading && <Loader />}
      <ModalComponent
        isOpen={actionModal}
        onClose={() => setActionModal(false)}
        closable={modalType === "more_report_subscription"}
        className={
          modalType === "renew_subscription"
            ? "wpb_expired__wrapper"
            : modalType === "open_report"
            ? "wpb_unlock__one_wrapper"
            : modalType === "more_report_subscription"
            ? "wpb_buy__reports_wrapper"
            : ""
        }
      >
        {modalType === "renew_subscription" ? (
          <div className="wpb_report__wrapper">
            <div className="vc_icon__general">
              <img src={subExpiredIcon} alt="" />
            </div>
            <div className="vc_content__general">
              <h2
                dangerouslySetInnerHTML={{
                  __html: t("PR_PHONE_TRACK_SUB_EXPIRED", {
                    subscription:
                      subscription === "tracking"
                        ? t("PR_PHONE_TRACKING")
                        : t("PR_PHONE_REPORT")
                  })
                }}
              ></h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    t("PR_RENEW_SUBSCRIPTION", {
                      date: endDate,
                      subscription:
                        subscription === "tracking"
                          ? t("PR_PHONE_TRACKING")
                          : t("PR_PHONE_REPORT")
                    }) + "."
                }}
              ></p>
            </div>
            <div className="vc_button__general">
              <button className="vc_btn3-inline" onClick={handleFlow}>
                {t("PR_RENEW_SUB_1")}
              </button>
            </div>
            <div className="vc_info__general">
              <p>
                {t("PR_MEMBERSHIP_RENEW", {
                  price: subscriptionStatus.subscriptionStatus
                    ? priceSymbol +
                      subscriptionStatus.subscriptionStatus?.data?.amount
                    : "$XX.XX"
                })}
              </p>
            </div>
          </div>
        ) : modalType === "more_report_subscription" ? (
          <AddMoreReport
            handleFlow={handleFlow}
            price={`${priceSymbol}${price}`}
          />
        ) : modalType === "open_report" ? (
          <div className="wpb_report__wrapper">
            <div className="vc_icon__general">
              <img src={unlockReportsIcon} alt="" />
            </div>
            <div className="vc_content__general">
              <h2>{t("PR_UNLOCK_REPORT_CONFIRMATION")}</h2>
              <p>
                {t("PR_UNLOCK_CAUTION", {
                  phone_number: formatNumberByCountry(formatedNumber)
                })}
              </p>
            </div>
            <div className="vc_button__general">
              <button className="vc_btn3-inline" onClick={openReport}>
                {t("PR_UNLOCK_REPORT")}
              </button>
            </div>
          </div>
        ) : null}
      </ModalComponent>
      <TransactionDeclinedModal
        isOpen={retryModal}
        // eslint-disable-next-line
        onClose={() => (setRetryModal(false), setStripeError(""))}
        closable={false}
        handleSubmit={onRetry}
        stripeError={stripeError}
        orderDetails={orderDetails}
      />
      {infoModal && (
        <PhoneNumberAttributeDetails
          isOpen={infoModal}
          onClose={() => {
            setInfoModal(false);
          }}
          handlePayment={handleOpenReport}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: state.subscriptionStatus,
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
    openReport: state.openReport,
    renewSubscription: state.renewSubscription,
    generatePayment: state.generatePayment,
    retryPayment: state.retryPayment,
    pricingTitleList: state.pricingTitleList?.getPhoneReportDetailsData?.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callOpenReport: (data) => dispatch(openReport(data)),
    callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
    callRenewSubscription: (data) => dispatch(renewSubscription(data)),
    callGeneratePayment: (data) => dispatch(generatePayment(data)),
    callRetryPayment: (data) => dispatch(retryPayment(data)),
    callUpdatePaymentMethod: (data) => dispatch(updatePaymentMethod(data)),
    callCreateSubscription: (data) => dispatch(createSubscription(data)),
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
    callAdditionalInfoStatus: (data) => dispatch(additionalInfoStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiActionModal);
