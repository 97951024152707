export const CONSTANTS = {
  STATUS: {
    ACTIVE: "active",
    CANCELED: "canceled",
    INCOMPLETE: "incomplete",
    PAST_DUE: "past_due",
    INCOMPLETE_EXPIRED: "incomplete_expired",
    TRIALING: "trialing"
  },
  SUBSCRIPTIONS: {
    TRACKING: "tracking",
    PHONE_REPORT: "phone_report",
    PREMIUM_REPORT: "premium_report",
    PDF_DOWNLOAD: "pdf_download"
  },
  WRONG_PRICING: {
    TRACKING: 49,
    PHONE_REPORT: 29.95,
    PREMIUM_REPORT: 24.95,
    PDF_DOWNLOAD: 4.95
  },
  ROLE: "user"
};
