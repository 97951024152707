import React, { useEffect, useState } from "react";
import {
  bglUnlockIcon,
  premiumLockIcon,
  securityIcon,
} from "../../../assets/images";
import "./unLockReport.scss";
import { checkAdminLogin, toCamelCase } from "../../../utils/commonUtils";
import { PhoneReportPayModal } from "../Modals";
import { connect } from "react-redux";

const UnlockReport = (props) => {
  const {
    title,
    image,
    buttonText,
    knownText,
    shouldKnowText,
    about,
    sectionName,
    pricingTitleList,
    pricingSectionName,
    id,
    phoneReportDetails,
  } = props;
  const [payModal, setPayModal] = useState(false);
  const [pricingData, setPricingData] = useState({});

  const { phone_number, phone_number_prefix } =
    phoneReportDetails?.phoneReport || {};
  const number = phone_number_prefix + phone_number;

  useEffect(() => {
    if (pricingTitleList) {
      setPricingData({
        label_1:
          pricingTitleList?.sections?.[pricingSectionName]?.amount || 3.95,
        label_2:
          pricingTitleList?.sections?.["unlock_all_section"]?.amount || 9.95,
        symbol: pricingTitleList?.sections?.[pricingSectionName]?.symbol || "$",
      });
    }
    // eslint-disable-next-line
  }, [pricingTitleList]);
  return (
    <>
      <div
        className="premium-report-wrap white-bg-wrap"
        id={toCamelCase(id ? id : title)}
      >
        <div className="left-content main_title toggle_area">
          <h2>{title}</h2>
        </div>
        <p className="about-text">{about}</p>
        <div className="premium-report-img">
          <img src={image} alt={"Lock Icon"} />
          <button
            className="phone-btn-info"
            onClick={() => setPayModal(true)}
            disabled={checkAdminLogin()}
          >
            <p>{buttonText}</p>
            <div className="banner-btn-icon">
              <img
                width={74} height={74}
                className="d-md-block"
                src={bglUnlockIcon}
                alt="UnLock Icon"
              />
              <img
                width={93} height={93}
                className="d-md-none"
                src={premiumLockIcon}
                alt="Lock Icon"
              />
            </div>
          </button>
        </div>
        <div className="premium-list-report">
          <h3>{shouldKnowText}</h3>
          <ul>
            <li>
              <img src={securityIcon} width={22} height={22} alt="Security Icon" className="right_icon" />{" "}
              {knownText}
            </li>
          </ul>
        </div>
      </div>
      <PhoneReportPayModal
        isOpen={payModal}
        onClose={() => setPayModal(false)}
        sectionName={sectionName}
        mode="section"
        title={title}
        pricingData={pricingData}
        number={number}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pricingTitleList: state.pricingTitleList?.getPhoneReportDetailsData?.data,
    phoneReportDetails:
      state.getPhoneReportDetails.getPhoneReportDetailsData.data,
  };
};

export default connect(mapStateToProps, null)(UnlockReport);
