import ModalComponent from "../../../common/Modal/modal";
import React, { useEffect, useState } from "react";
import "../phoneReportPayModal/phoneReportPayModal.scss";
import { useTranslation } from "react-i18next";
import UnlockAnimation from "../../../../lottieAnimations/UnlockLottieAnimation";
import { connect } from "react-redux";
import downloadImage from "../../../../assets/images/downloadPDF.svg";
import { downloadReport } from "../../../../redux/slice/downloadReportSlice";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { confirmPayment, generatePdfBlob } from "../../../../utils/commonUtils";
import { generatePayment } from "../../../../redux/slice/generatePaymentSlice";
import Notify from "../../../../../components/common/Notify/notify";
import { uploadPdf } from "../../../../redux/slice/uploadPdfSlice";
import TransactionDeclinedModal from "../../../../pages/transactionDeclined/transactionDeclined";
import { createPdfSubscription } from "../../../../redux/slice/createPdfSubscriptionSlice";
import { retryPayment } from "../../../../redux/slice/retryPaymentSlice";
import { updatePaymentMethod } from "../../../../redux/slice/updatePaymentMethodSlice";
import { getPhoneReportDetails } from "../../../../redux/slice";
import { Cookies } from "react-cookie";

const DownloadReportPayModal = (props) => {
  const {
    isOpen,
    onClose,
    // API
    callDownloadReport,
    phoneReportDetails,
    callGeneratePayment,
    callUploadPdf,
    callCreatePdfSubscription,
    callRetryPayment,
    callUpdatePaymentMethod,
    pricingData,
    callGetPhoneReportDetails,
  } = props;

  const { t } = useTranslation();
  const { phoneReport } = phoneReportDetails || {};
  const stripe = useStripe();
  const elements = useElements();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const signUpTerms = t("PR_UNLOCK_POPUP_TERM");
  const modifiedLink = signUpTerms.replaceAll(
    '<a href="https://reversly.com/',
    '<a href="https://reversly.com/' + lang + "/"
  );

  const [selectedOption, setSelectedOption] = useState(
    "download_unlimited_report"
  );
  const [amount, setAmount] = useState(1785);
  const [retryModal, setRetryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [termStatus, setTermStatus] = useState(false);
  const [termError, setTermError] = useState("");
  const [stripeError, setStripeError] = useState("");

  useEffect(() => {
    if (pricingData && pricingData.label_2) {
      setAmount(pricingData.label_2);
    }
  }, [pricingData]);

  const handleClose = () => {
    onClose();
    setTermError("");
    setTermStatus(false);
  };
  const handleChange = (e, amount) => {
    setSelectedOption(e.target.value);
    setAmount(amount);
  };
  const handleChangeTerm = (event) => {
    setTermStatus(event.target.checked);
    setTermError("");
  };
  const handlePayForPdf = async () => {
    if (!termStatus) {
      setTermError(t("PR_ACCEPT_TERM"));
      return;
    }
    setIsLoading(true);
    handleClose();
    try {
      if (selectedOption === "download_this_report") {
        const payload = {
          price_title: "download_one_report",
          type: "one-time",
        };
        const res = await callGeneratePayment(payload);
        if (res.type === "generatePayment/fulfilled") {
          const { client_secret, payment_method } = res.payload.data || {};
          const { error, paymentIntent } = await confirmPayment(
            stripe,
            client_secret,
            payment_method
          );
          if (error) {
            setRetryModal(true);
          }
          const downloadPayload = {
            phone_report_id: phoneReport?._id,
            payment_intent_id: paymentIntent.id,
          };
          const downloadRes = await callDownloadReport(downloadPayload);
          if (downloadRes.type === "downloadReport/fulfilled") {
            callGetPhoneReportDetails(phoneReport?._id);
            const pdfBlob = await generatePdfBlob("main_root");
            const formData = new FormData();
            formData.append("pdfFile", pdfBlob, "report.pdf");
            formData.append("phone_report_id", phoneReport?._id);
            const uploadRes = await callUploadPdf(formData);
            if (uploadRes.type === "uploadPdf/fulfilled") {
              Notify("success", uploadRes.payload.message, "");
              const { url } = uploadRes.payload.data || {};
              if (url) {
                window.location.href = url;
              }
            } else {
              Notify("error", uploadRes.error.message, "");
            }
          } else {
            Notify("error", downloadRes.error.message, "");
          }
        } else {
          Notify("error", res.error.message, "");
        }
      } else if (selectedOption === "download_unlimited_report") {
        const payload = {
          price_title: "pdf_download",
          type: "subscription",
        };
        const res = await callGeneratePayment(payload);
        if (res.type === "generatePayment/fulfilled") {
          const { client_secret, payment_method, subscription_id } =
            res.payload.data || {};
          const { error } = await confirmPayment(
            stripe,
            client_secret,
            payment_method
          );
          if (error) {
            setRetryModal(true);
          } else {
            const subscriptionRes = await callCreatePdfSubscription(
              subscription_id
            );
            if (subscriptionRes.type === "createPdfSubscription/fulfilled") {
              callGetPhoneReportDetails(phoneReport?._id);
              Notify("success", subscriptionRes.payload.message, "");
            } else {
              Notify("error", subscriptionRes.error.message, "");
            }
          }
        } else {
          Notify("error", res.error.message, "");
        }
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRetry = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    try {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const { error: paymentMethodError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardNumberElement,
        });
      if (paymentMethodError) {
        setStripeError(paymentMethodError.message);
        return;
      }
      const isSingleReport = selectedOption === "download_this_report";
      const data = {
        price_title: isSingleReport ? "download_one_report" : "pdf_download",
        type: isSingleReport ? "one-time" : "subscription",
      };

      const res = await callRetryPayment(data);

      if (res.type === "retryPayment/fulfilled") {
        const { client_secret: clientSecret, subscription_id } =
          res.payload.data || {};
        const { error: paymentIntentError, paymentIntent } =
          await confirmPayment(stripe, clientSecret, paymentMethod.id);

        if (paymentIntentError) {
          elements.getElement(CardNumberElement).clear();
          elements.getElement(CardExpiryElement).clear();
          elements.getElement(CardCvcElement).clear();
          setRetryModal(false);
          setTimeout(() => {
            setRetryModal(true);
            setStripeError("");
          }, 500);
        } else {
          setRetryModal(false);

          if (isSingleReport) {
            const payload = {
              phone_report_id: phoneReport?._id,
              payment_intent_id: paymentIntent.id,
            };

            const reportRes = await callDownloadReport(payload);

            if (reportRes.type === "downloadReport/fulfilled") {
              callGetPhoneReportDetails(phoneReport?._id);
              const pdfBlob = await generatePdfBlob("main_root");
              const formData = new FormData();
              formData.append("pdfFile", pdfBlob, "report.pdf");
              formData.append("phone_report_id", phoneReport?._id);

              const uploadRes = await callUploadPdf(formData);

              if (uploadRes.type === "uploadPdf/fulfilled") {
                Notify("success", uploadRes.payload.message, "");
                const { url } = uploadRes.payload.data || {};
                if (url) {
                  window.location.href = url;
                }
              } else {
                Notify("error", uploadRes.error.message, "");
              }
            } else {
              Notify("error", reportRes.error.message, "");
            }
          } else {
            const subscriptionRes = await callCreatePdfSubscription(
              subscription_id
            );

            if (subscriptionRes.type === "createPdfSubscription/fulfilled") {
              callGetPhoneReportDetails(phoneReport?._id);
              Notify("success", subscriptionRes.payload.message, "");
            } else {
              Notify("error", subscriptionRes.error.message, "");
            }
          }
          await callUpdatePaymentMethod(paymentMethod.id);
        }
      } else {
        Notify("error", res.error.message, "");
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const orderDetails = {
    orderName: t("PR_PDF_DOWNLOAD"),
    orderPrice: pricingData?.symbol + amount,
  };

  return (
    <>
      {isLoading && <UnlockAnimation />}
      {isOpen && (
        <ModalComponent isOpen={isOpen} onClose={handleClose} closable={false}>
          <div className="multiple_popup__wrap">
            <div className="wpb_report_popup fixed-size">
              <img src={downloadImage} alt="" height={100} width={100} />
              <div className="mlt_popup__text">
                <h2>{t("PR_DOWNLOAD_REPORT_2")}</h2>
                <p>
                  {selectedOption === "download_unlimited_report"
                    ? t("PR_DOWNLOAD_POPUP_DESCRIPTION_2")
                    : t("PR_DOWNLOAD_POPUP_DESCRIPTION")}
                </p>
              </div>
              <div className="mlt_popup__payment">
                <form action="/">
                  <label htmlFor="unlock_report" className="dsl_report">
                    <div className="dsl_report_field">
                      <input
                        type="radio"
                        id="unlock_report"
                        name="report_option"
                        value="download_this_report"
                        checked={selectedOption === "download_this_report"}
                        onChange={(e) =>
                          handleChange(
                            e,
                            isOpen && pricingData.label_1 && pricingData.label_1
                          )
                        }
                      />
                      <span className="report_radio_wrap">
                        {t("PR_DOWNLOAD_REPORT")}
                      </span>
                    </div>
                    <div className="dsl_report_payment">
                      <span>
                        {isOpen &&
                          pricingData.label_1 &&
                          pricingData.symbol + pricingData.label_1}
                      </span>
                    </div>
                  </label>
                  <label htmlFor="due_now" className="dsl_report">
                    <div className="dsl_report_field">
                      <input
                        type="radio"
                        id="due_now"
                        name="report_option"
                        value="download_unlimited_report"
                        checked={selectedOption === "download_unlimited_report"}
                        onChange={(e) =>
                          handleChange(
                            e,
                            isOpen && pricingData.label_2 && pricingData.label_2
                          )
                        }
                      />
                      <span className="report_radio_wrap">
                        {t("PR_DOWNLOAD_REPORT_SUB_TITLE")}
                      </span>
                    </div>
                    <div className="dsl_report_payment">
                      <span>
                        {isOpen &&
                          pricingData.label_2 &&
                          pricingData.symbol + pricingData.label_2}
                        {t("PR_UNLOCK_POPUP_TEXT_3")}
                      </span>
                      <small>{t("PR_UNLIMITED")}</small>
                    </div>
                  </label>
                </form>
              </div>
              <div className="mlt_popup__subscribe">
                <div className="policy__content_wrap">
                  <input
                    type="checkbox"
                    id="tracelo_Privacy"
                    name="Privacy Content"
                    onChange={handleChangeTerm}
                  />
                  <label
                    htmlFor="tracelo_Privacy"
                    dangerouslySetInnerHTML={{
                      __html: modifiedLink,
                    }}
                  ></label>
                </div>
                <div className="error">
                  <p>{termError}</p>
                </div>
                <div className="report__popup_pay_btn">
                  <button type="submit" onClick={handlePayForPdf}>
                    {t("PR_PAY")} {pricingData.label_1 && pricingData.symbol}{" "}
                    {amount}
                  </button>
                  <p>
                    <span onClick={handleClose}>{t("PR_CANCEL")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
      {retryModal && (
        <TransactionDeclinedModal
          isOpen={retryModal}
          // eslint-disable-next-line
          onClose={() => (setRetryModal(false), setStripeError(""))}
          closable={false}
          handleSubmit={onRetry}
          stripeError={stripeError}
          orderDetails={orderDetails}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callDownloadReport: (data) => dispatch(downloadReport(data)),
    callGeneratePayment: (data) => dispatch(generatePayment(data)),
    callUploadPdf: (data) => dispatch(uploadPdf(data)),
    callCreatePdfSubscription: (data) => dispatch(createPdfSubscription(data)),
    callRetryPayment: (data) => dispatch(retryPayment(data)),
    callUpdatePaymentMethod: (data) => dispatch(updatePaymentMethod(data)),
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadReportPayModal);
