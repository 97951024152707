import React, { useEffect } from "react";
import "./homePage.scss";
import PageBannerContent from "../pageBanner";
import { useAppContextInput } from "../../App";
import HomePageSection from "../reversly/homePageSection/homePageSection";

const HomePageComponent = (props) => {
  const { contant } = props;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const inputs = document.getElementsByClassName("form-control");
  if (inputs && inputs.length > 0) {
    inputs[0] && (inputs[0].disabled = true);
    inputs[1] && (inputs[1].disabled = true);
  }
  const { inputRef, mobileInputRef, plan } =
    useAppContextInput();
  // Auto default focus on phone field
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div className="homePage-main-section">
      <div className="bg-grad-section">
        {contant !== "none" && (
          <PageBannerContent
            from="none"
            inputRef={inputRef}
            mobileInputRef={mobileInputRef}
            plan={plan}
          />
        )}
      </div>
      <HomePageSection />
    </div>
  );
};

export default HomePageComponent;
