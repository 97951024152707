import React from "react";
import "./transactionDeclined.scss";
import {
  transactionsDeclinedIcon,
  visaPaymentCardImg,
} from "../../assets/images";
import ModalComponent from "../../components/common/Modal/modal";
import { useTranslation } from "react-i18next";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { Cookies } from "react-cookie";

const TransactionDeclinedModal = ({
  isOpen,
  onClose,
  closable,
  handleSubmit,
  stripeError,
  orderDetails,
}) => {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const lang = cookies.get("lang");

  const elementStyles = {
    base: {
      padding: "50px",
      height: "20px",
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
  };
  var elementClasses = {
    focus: "focus",
    empty: "empty",
    invalid: "invalid",
  };
  const cardOptions = {
    style: elementStyles,
    classes: elementClasses,
  };

  const cardExpiryOptions = {
    style: elementStyles,
    placeholder: "MM/YY",
  };

  const cardCvcOptions = {
    style: elementStyles,
    placeholder: "CVV/CVC",
  };

  const signUpTerms = t("PR_UNLOCK_POPUP_TERM");
  const modifiedLink = signUpTerms.replaceAll(
    '<a href="https://reversly.com/',
    '<a href="https://reversly.com/' + lang + "/"
  );
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={closable}
      className="transaction-declined-modal"
      destroyOnClose={true}
    >
      <div className="transaction__popup">
        <div className="main_container">
          <div className="transaction_bg_wrap">
            <div className="transaction__title">
              <div className="transaction__img">
                <img
                  src={transactionsDeclinedIcon}
                  alt="Transaction Declined"
                />
              </div>
              <h2>{t("PR_TRANSACTION_DECLINED")}</h2>
              <p>{t("PR_TRANSACTION_DESCRIPTION")}</p>
            </div>
            <div className="transaction_form">
              <form action="/">
                <div className="form-container">
                  <div className="field-container">
                    <label htmlFor="cardnumber">
                      {t("PR_TRANSACTION_CARD_NO")}
                    </label>
                    {/* <input
                      className="cq_panel__components main-phone-card"
                      id="cardnumber"
                      type="text"
                      minLength="16"
                      maxLength="16"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      placeholder="Card Number"
                    /> */}
                    <div className="cq_panel__components main-phone-card">
                      <CardNumberElement options={cardOptions} />
                    </div>
                  </div>
                  <div className="field-container">
                    <label htmlFor="expirationdate">
                      {t("PR_TRANSACTION_EXPIRATION_DATE")}
                    </label>
                    {/* <input
                      className="cq_panel__components main-phone-date"
                      id="expirationdate"
                      type="text"
                      minLength="5"
                      maxLength="5"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      placeholder="(MM/YY)"
                    /> */}
                    <div className="cq_panel__components main-phone-date">
                      <CardExpiryElement options={cardExpiryOptions} />
                    </div>
                  </div>
                  <div className="field-container">
                    <label htmlFor="securitycode">
                      {t("PR_TRANSACTION_CVC")}
                    </label>
                    {/* <input
                      className="cq_panel__components main-phone-cvv"
                      id="securitycode"
                      type="password"
                      minLength="4"
                      maxLength="4"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      placeholder="CVV/CVC"
                    /> */}
                    <div className="cq_panel__components main-phone-cvv">
                      <CardCvcElement options={cardCvcOptions} />
                    </div>
                  </div>
                  {/* <div className="field-container">
                    <label htmlFor="zip">{t("ZIP_CODE")}</label>
                    <input
                      type="text"
                      id="zip"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      name="zip"
                      placeholder="Zip Code"
                    />
                  </div>
                  <div className="field-container">
                    <label htmlFor="email">{t("PR_TRANSACTION_EMAIL")}</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      inputMode="text"
                      placeholder="Enter Email"
                    />
                  </div> */}
                </div>
                <div className="order__field">
                  <span>{t("PR_ORDER_DETAILS")}:</span>
                  {orderDetails && (
                    <div className="order__details">
                      <span>{orderDetails?.orderName || "Order"}</span>
                      {orderDetails?.orderPrice && (
                        <strong>{orderDetails?.orderPrice || "€39.50"}</strong>
                      )}
                    </div>
                  )}
                </div>
                {stripeError && (
                  <p className="stripe_retry_error">{stripeError}</p>
                )}
                <div className="submit_button">
                  <button
                    className="payment__Submit"
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {t("PR_SUBMIT_ORDER")}
                  </button>
                </div>
                <div className="terms__Privacy">
                  <label
                    htmlFor="Privacy-terms"
                    dangerouslySetInnerHTML={{
                      __html: modifiedLink,
                    }}
                  ></label>
                </div>
                <div className="visa_card">
                  <img src={visaPaymentCardImg} alt="Visa" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default TransactionDeclinedModal;
