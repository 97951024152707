import React, { Suspense, useEffect } from "react";
import phoneScoreImage from "../../assets/newhome/phoneScoreImage.webp";
import googleMapImageWebp from "../../assets/optimized-images/googleMapImage-web.webp";
import googleMapImagePng from "../../assets/optimized-images/googleMapImage-png.png";
import countryLogoImage from "../../assets/newhome/countryLogoImage.svg";
import cityCheckIcon from "../../assets/newhome/cityCheckIcon.svg";
import ProviderAtIcon from "../../assets/newhome/ProviderAtIcon.svg";
import whatsAppImage from "../../assets/newhome/whatsAppImage.svg";
import facebookIcon from "../../assets/newhome/facebookIcon.svg";
import instagramIcon from "../../assets/newhome/instagramIcon.svg";
import linkedInIcon from "../../assets/newhome/linkedInIcon.svg";
import tikTokIcon from "../../assets/newhome/tikTokIcon.svg";
import ownerPhotosImagePng from "../../assets/optimized-images/ownerPhotosImage-png.png";
import checkEmailStatusIcon from "../../assets/newhome/checkEmailStatusIcon.svg";
import countryPatternImg from "../../assets/newhome/countryPatternImg.svg";
import cityPatternImg from "../../assets/newhome/cityPatternImg.svg";
import providerPatternImg from "../../assets/newhome/providerPatternImg.svg";
import statusPatternImg from "../../assets/newhome/statusPatternImg.svg";
import trustedSiteIcon from "../../assets/newhome/trustedSiteIcon.svg";
import bannerReviewIcon from "../../assets/newhome/bannerReviewIcon.svg";
import bannerPrivacyIcon from "../../assets/newhome/bannerPrivacyIcon.svg";
import { useTranslation } from "react-i18next";
import { useAppContextInput } from "../../App";
import bannerPhoneImagepng from "../../assets/optimized-images/bannerPhoneImage-png.png";
import bannerPhoneImageWebp from "../../assets/optimized-images/bannerPhoneImage-web.webp";
import BannerBgPatternWebp from "../../assets/newhome/BannerBgPattern-webp.webp";
import backgroundBannerImages from "../../assets/newhome/backgroundBannerImages.svg";
import BannerBgPatternSvg from "../../assets/newhome/BannerBgPattern.svg";

const PhoneInputComponent = React.lazy(() => import("../common/phoneInputComponent/phoneInputComponent"));

const PageBannerContent = (props) => {
  const { url, fx, plan, signUpUrl } = props;
  const utm_term = fx?.replaceAll("-", " ");
  const { t } = useTranslation();
  const { inputRef, mobileInputRef } = useAppContextInput();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const pageVersion = url === "track" || url === "trace" ? "b" : "w";

  return (
    <div>
      <div className="HomeBanner_Wrapper padding_wrap pb-0 bg-white">
      <img fetchPriority="high" className="wpb__codewrapper_image" src={backgroundBannerImages} width={100} height={100} alt="Background Banner"/>
        <div className="NewContainer">
          <div className="HomeBanner__Info">
            <div className="HomeBanner__Content">
              <div className="section__title_wrapper"
              dangerouslySetInnerHTML={{
                __html: t("PR_TITLE_PHONE_LOCKUP"),
              }}
              ></div>
              <p dangerouslySetInnerHTML={{
                  __html: utm_term
                  ? utm_term.charAt(0).toUpperCase() + utm_term.slice(1)
                  : t("PR_LOOKUP_DESCRIPTION"),
                }}>
              </p>
            <Suspense fallback={<div>Loading...</div>}>
              <PhoneInputComponent
                pageVersion={pageVersion}
                signUpUrl={signUpUrl}
                url={url}
                plan={plan}
                inputRef={inputRef}
                mobileInputRef={mobileInputRef}
              />
            </Suspense>
              
              <div className="BannerReviewInfo">
                <div className="BannerReviewslogs">
                  <div className="BannerReviewsIcon">
                  <img src={trustedSiteIcon} alt="TrustedSite" width={26} height={26}  />
                  </div>
                  <div className="BannerReviewsContent">
                    {t("PR_HOME_BANNER_TRUSTED")}
                    <small>®</small>
                    </div>
                </div>
                <hr className="vertical-line"></hr>
                <div className="BannerReviewslogs">
                  <div className="BannerReviewsIcon">
                  <img src={bannerReviewIcon} alt="Reviews"  width={24} height={24}  />
                  </div>
                  <div className="BannerReviewsContent">
                    {t("PR_REVIEWS", { reviews:"30k "})}
                  </div>
                </div>
                <hr className="vertical-line"></hr>
                <div className="BannerReviewslogs BannerReviewsPrivacy">
                <div className="BannerReviewsIcon">
                <img src={bannerPrivacyIcon} alt="Privacy" width={12} height={12}  />
                  </div>
                  <div className="BannerReviewsContent">
                    {t("PR_HOME_BANNER_RESPECT_PRIVACY")}
                  </div>
                </div>
              </div>
              <div className="BannerListItem__Wrapper">
                <ul>
                  <li>{t("PR_REVEAL_CALLER")}</li>
                  <li>{t("PR_PHOTOS_AND_SOCIAL")}</li>
                  <li>{t("PR_CONFIDENTIAL_AND_INSTANT")}</li>
                </ul>
              </div>
            </div>

            <div className="HomeBanner__Image">
              <div className="BannerImageWrapper">
              <div className="BannerImagePattern">
                <picture>
                  <source srcSet={BannerBgPatternWebp} type="image/webp" />  
                  <img  fetchPriority="high" src={BannerBgPatternSvg} alt="Hero Banner Background" width={100} height={100} />  
                </picture>
              </div>
                <div className="BannerPhoneImage">
                  <picture>  
                    <source srcSet={bannerPhoneImageWebp} type="image/webp" />
                    <img src={bannerPhoneImagepng} alt={t("PR_BANNER_PHONE_IMAGE_ALT")} width={359} height={665}  />
                  </picture>
                  <span className="BannerCallerContent">{t("PR_ANONYMOUS_CALLER")}</span>
                  <div className="BannerCustomBoxWrap">
                    <div className="WplBannerImageBox1">
                      <div className="BannerPhoneScoreBox">
                        <div className="BannerPhoneScoreContent">
                          <h2>{t("PR_PHONE_SCORE")}</h2>
                          <span>823</span>
                        </div>
                        <div className="BannerPhoneScoreImage">
                          <picture>
                            <source srcSet={phoneScoreImage} type="image/webp" />
                            <img src={phoneScoreImage} alt={t("PR_PHONE_SCORE_IMG_ALT")} fetchPriority="high" width={108} height={63} />
                          </picture>
                        </div>
                        <div className="BannerPhoneScoreButton">
                          <button>{t("PR_SAFE")}</button>
                        </div>
                      </div>
                    </div>

                    <div className="WplBannerImageBox2">
                      <div className="BannerImageMapInfo">
                        <div className="BannerMapImage">
                          <picture>
                            <source srcSet={googleMapImageWebp} type="image/webp" />  
                            <img src={googleMapImagePng} alt={t("PR_BANNER_PHONE_IMAGE_ALT")} width={240} height={105} />  
                          </picture>
                        </div>
                        <div className="BannerNumberLocate">
                          <a href="tell:+1 (555) 285 8371">+1 (555) 285 8371</a>
                        </div>
                        <div className="BannerLocationList">
                          <ul>
                            <li>
                              <div className="BannerLocationArea">
                                <pre>{t("COUNTRY")}</pre>
                                <div className="LocateDesignPattern">
                                 <img src={countryPatternImg} alt={t("PR_COUNTRY_PATTERN_ALT")} width={37} height={8}  />
                                </div>
                                <span>
                                  <img src={countryLogoImage} alt={t("PR_COUNTRY_LOGO_ALT")} width={17} height={17}  />
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="BannerLocationArea">
                                <pre>{t("CITY")}</pre>
                                <div className="LocateDesignPattern">
                                <img src={cityPatternImg} alt={t("PR_CITY_CHECK_ALT")} width={55} height={8}  /> 
                                </div>
                                <span>
                                <img src={cityCheckIcon} alt={t("PR_CITY_CHECK_ALT")} width={17} height={17}   />  
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="BannerLocationArea">
                                <pre>{t("PR_PROVIDER")}</pre>
                                <div className="LocateDesignPattern">
                                <img src={providerPatternImg} alt={t("PR_PROVIDER_PATTERN_ALT")} width={38} height={8}   />
                                </div>
                                <span className="BannerLocationProvider">
                                <img src={ProviderAtIcon} alt={t("PR_PROVIDER_ICON_ALT")} width={17} height={17}   />
                                  {t("PR_AT_T")}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="BannerLocationArea">
                                <pre>{t("PR_STEP_3_STATUS")}</pre>
                                <div className="LocateDesignPattern">
                                <img src={statusPatternImg} alt={t("PR_STATUS_PATTERN_ALT")} width={38} height={8}   />
                                </div>
                                <span className="BannerLocationStatus">
                                  • {t("PR_VALID")}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="WplBannerImageBox3">
                      <div className="BannerPhoneInformation">
                        <div className="BannerPhoneInformationBox">
                          <div className="BannerPhoneInformationImage">
                          <img src={checkEmailStatusIcon} alt={t("PR_CHECK_EMAIL_STATUS_ALT")} width={28} height={28}  />
                          </div>
                          <div className="BannerPhoneInformationContent">
                            <h4>{t("PR_EMAIL")}</h4>
                            <p>{t("PR_EMAIL_FOUND")}</p>
                          </div>
                        </div>
                        <div className="BannerPhoneInformationBox">
                          <div className="BannerPhoneInformationImage">
                          <img src={checkEmailStatusIcon} alt={t("PR_CHECK_EMAIL_STATUS_ALT")} width={28} height={28}  />
                          </div>
                          <div className="BannerPhoneInformationContent">
                            <h4>{t("PR_LOCATION")}</h4>
                            <p>{t("PR_STEP_2_TITLE")}</p>
                          </div>
                        </div>
                        <div className="BannerPhoneInformationBox justify-content">
                          <div className="BannerPhoneInformationImage">
                          <img src={checkEmailStatusIcon} alt={t("PR_CHECK_EMAIL_STATUS_ALT")} width={28} height={28}  /> 
                          </div>
                          <div className="BannerPhoneInformationContent">
                            <h4>{t("PR_OWNER_PHOTOS")}</h4>
                            <p>{t("PR_PHOTOS_FOUND")}</p>
                          </div>
                          <div className="BannerPhoneOwnerInfo">
                          <img src={ownerPhotosImagePng} alt={t("PR_BANNER_PHONE_IMAGE_ALT")} width={37} height={28} /> 
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="WplBannerImageBox4">
                      <div className="BannerConnectedAccountsBox">
                        <h4>{t("PR_CONNECTED_ACCOUNTS")}</h4>
                        <div className="BannerSocialInfo">
                        <img src={whatsAppImage} alt={t("PR_WHATSAPP_ALT")} width={24} height={23}  />
                          <h5>WhatsApp</h5>
                          <div className="BannerSocialActive">
                            • {t("PR_CONNECTED")}
                          </div>
                        </div>
                        <div className="BannerSocialInfo">
                        <img src={facebookIcon} alt={t("PR_WHATSAPP_ALT")} width={24} height={24}  /> 
                          <h5>Facebook</h5>
                          <div className="BannerSocialActive">
                            • {t("PR_CONNECTED")}
                          </div>
                        </div>
                        <div className="BannerSocialInfo">
                        <img src={instagramIcon} alt={t("PR_INSTAGRAM_ALT")} width={24} height={23}  />
                          <h5>Instagram</h5>
                          <div className="BannerSocialActive">
                            • {t("PR_CONNECTED")}
                          </div>
                        </div>
                        <div className="BannerSocialInfo">
                        <img src={linkedInIcon} alt={t("PR_LINKEDIN_ALT")} width={24} height={23}  />
                          <h5>Linkedin</h5>
                          <div className="BannerSocialActive">
                            • {t("PR_CONNECTED")}
                          </div>
                        </div>
                        <div className="BannerSocialInfo">
                        <img src={tikTokIcon} alt={t("PR_TIKTOK_ALT")} width={24} height={24}  />
                          <h5>TikTok</h5>
                          <div className="BannerSocialActive BannerSocialNonActive">
                            • {t("PR_NOT_FOUND")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBannerContent;
