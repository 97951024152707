/* global gtag */
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
import {
  handleSetNumber,
  handleSetPhoneNumber,
} from "../../../utils/commonUtils";
import { useTranslation } from "react-i18next";
import { Input, Form } from "antd";
import { CloseOutlined as IoMdClose } from "@ant-design/icons";
import parsePhoneNumberFromString from "libphonenumber-js";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";

const PhoneInputComponent = ({
  pageVersion,
  signUpUrl,
  url,
  plan,
  sendDetailsData,
  inputRef,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const token = cookies.get("token");
  const lang = cookies.get("lang");
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCodeinput, setCountryCode] = useState("ca");
  const [numberValidate, setNumberValidate] = useState("");
  const [dialCode, setDialCode] = useState("+1");
  const [prevLocation, setPrevLocation] = useState(location.pathname);
  const [finalFormattedNumber, setFinalFormattedNumber] = useState(
    location.pathname
  );
  const [renderRTL, setRenderRTLClass] = useState(
    ["ar", "he"].includes(window.location.pathname.split("/")[1])
  );

  useEffect(() => {
    setRenderRTLClass(
      ["ar", "he"].includes(window.location.pathname.split("/")[1])
    );
  }, [window.location.pathname]);

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      setPrevLocation(location.pathname);
      setNumberValidate("");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (sendDetailsData) {
      setDialCode(sendDetailsData.data.country_calling_code);
      setCountryCode(sendDetailsData.data.country_code.toLowerCase());
    }
    // eslint-disable-next-line
  }, [sendDetailsData]);

  const handleNavigate = () => {
    if (finalFormattedNumber && phoneNumber) {
      const parsedNumber = parsePhoneNumberFromString(
        phoneNumber,
        countryCodeinput.replace("+", "").toUpperCase()
      );
      if (parsedNumber) {
        let trimmedNumber = finalFormattedNumber?.trim();

        if (trimmedNumber.startsWith(dialCode)) {
          trimmedNumber = trimmedNumber.substring(dialCode.length).trim();
          if (
            trimmedNumber
              .replace(" ", "")
              .replace("-", "")
              .replace("(", "")
              .replace(")", "").length < 10
          ) {
            if (dialCode.length <= 2) {
              trimmedNumber = dialCode + trimmedNumber;
            }
          }
        }

        if (phoneNumber && phoneNumber !== "0") {
          // parsedNumber.country === countryCodeinput.replace("+", "").toUpperCase()
          if (
            parsedNumber &&
            parsedNumber.isValid() &&
            parsedNumber.country ===
              countryCodeinput.replace("+", "").toUpperCase()
          ) {
            gtag("event", "track_phone_number", {
              phoneNumber: phoneNumber,
              page_version: pageVersion,
            });
            if (token) {
              navigate(`/${lang}/dash`);
            } else {
              navigate(
                `/${lang}/${
                  signUpUrl
                    ? "tracking/"
                    : url === "track" || url === "trace"
                    ? `${url}/`
                    : ""
                }${url === "spreedlyWithSignup" ? "signup" : "sign-up"}${
                  plan === "subscription" ? `/${plan}` : ""
                }?D=${dialCode}&n=${parsedNumber.nationalNumber}`,
                {
                  state: {
                    phoneNumber: trimmedNumber,
                  },
                }
              );
            }
          } else {
            setNumberValidate(t("ERROR_VALID_NUMBER_HOME"));
          }
        } else {
          setNumberValidate(t("ERROR_VALID_NUMBER_HOME"));
        }
      } else {
        setNumberValidate(t("ERROR_VALID_NUMBER_HOME"));
      }
    } else {
      setNumberValidate(t("ERROR_ENTER_NUMBER_HOME"));
    }
  };
  return (
    <div className="wrap-right-input-error reverslyHomeBannerInput">
      <Form onSubmit={handleNavigate}>
        <div className="right-text head-input wpb_content_input">
          <div className="reverslyInputDesktop">
            <PhoneInput
              country={countryCodeinput || "ca"}
              onChange={(value, country) =>
                handleSetNumber(
                  value,
                  country,
                  setDialCode,
                  setNumberValidate,
                  setCountryCode,
                  setPhoneNumber,
                  setFinalFormattedNumber,
                  phoneNumber
                )
              }
              placeholder=""
              className={"desktop-input"}
              countryCodeEditable={false}
              autoFormat={true}
              inputProps={{
                name: "phone",
                className: "phone-input-field",
                disabled: true,
              }}
              enableSearch={true}
              searchStyle={{
                width: "100%",
                height: "35px",
                borderRadius: "7px",
                margin: "0px",
                padding: "0px; !important",
                border: " #0000002b 1px solid",
              }}
              disableSearchIcon={true}
              searchPlaceholder={t("SEARCH")}
              searchNotFound={t("NO_MATCHED")}
              onEnterKeyPress={handleNavigate}
            />
            <Input
              ref={inputRef}
              className={`desktop-input-nd desktop-form-control desk ${
                renderRTL ? "input_ltr" : null
              } `}
              onChange={(event) =>
                handleSetPhoneNumber(
                  event,
                  setPhoneNumber,
                  setNumberValidate,
                  setCountryCode,
                  countryCodeinput,
                  setDialCode,
                  setFinalFormattedNumber,
                  inputRef
                )
              }
              placeholder={t("PR_PH_LOOKUP")}
              maxLength="20"
              type="text"
              inputMode="tel"
              value={phoneNumber}
              id="phone_input"
              onEnterKeyPress={handleNavigate}
            />
          </div>
          <div className="reverslyInputMobile">
            <PhoneInput
              country={countryCodeinput || "ca"}
              onChange={(value, country) =>
                handleSetNumber(
                  value,
                  country,
                  setDialCode,
                  setNumberValidate,
                  setCountryCode,
                  setPhoneNumber,
                  setFinalFormattedNumber
                )
              }
              placeholder=""
              className={"mobile-input"}
              autoFormat={true}
              inputProps={{
                name: "phone",
                className: "phone-input-field",
                disable: true,
              }}
              onEnterKeyPress={handleNavigate}
              enableSearch={true}
              searchStyle={{
                width: "100%",
                height: "35px",
                borderRadius: "7px",
                margin: "0px",
                padding: "0px; !important",
                border: " #0000002b 1px solid",
              }}
              disableSearchIcon={true}
              searchPlaceholder={t("SEARCH")}
              searchNotFound={t("NO_MATCHED")}
            />

            <Input
              className={`desktop-input-nd mobile mobile-form-control ${
                renderRTL ? "input_ltr" : null
              } `}
              onChange={(event) =>
                handleSetPhoneNumber(
                  event,
                  setPhoneNumber,
                  setNumberValidate,
                  setCountryCode,
                  countryCodeinput,
                  setDialCode,
                  setFinalFormattedNumber
                )
              }
              placeholder={t("PR_PH_LOOKUP")}
              maxLength="20"
              type="text"
              inputMode="tel"
              value={phoneNumber}
              onEnterKeyPress={handleNavigate}
            />
          </div>
          <button
            className="input-suffix reversly_cta_wrap"
            onClick={handleNavigate}
            type="submit"
          >
            <span className="span-text">{t("SEARCH")}</span>
          </button>
        </div>
      </Form>

      {numberValidate && (
        <p className="invelid-number-error">
          {numberValidate} <IoMdClose onClick={() => setNumberValidate("")} />{" "}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sendDetailsData: state.sendDetailsData.getSendDetailsData,
  };
};

export default connect(mapStateToProps, null)(PhoneInputComponent);
