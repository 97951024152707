/* global gtag */
import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import HomePage from "../components/home";
import FAQ from "../components/faq";
import Contact from "../components/contact";
import Unsubscribe from "../components/unsubscribe";
import Pricing from "../components/pricing";
import Login from "../components/login";
import ResetPassword from "../components/reset-password";
import SignUp from "../components/signUp";
import SignUpSpreedly from "../components/signUpSpreedly";
import { PrivacyPolicy, TermConditions } from "../phoneReport/pages";
import Trace from "../components/trace";
import { Cookies } from "react-cookie";
import { connect } from "react-redux";
import { authenticate } from "../redux/slice/authenticateSlice";
import { productList } from "../redux/slice/productListSlice";
import NotFoundPage from "../components/notFoundPage";
import { useTranslation } from "react-i18next";
import { languages, navTokenArray } from "../utils/commonUtils";
import { checkAdminLogin, getUserId } from "../phoneReport/utils/commonUtils";
import { gclidCheck } from "../redux/slice/gclidCheckSlice";
import { getGclidDetails } from "../redux/slice/getGclidDetailsSlice";
import TracePage from "../components/tracePage";
import { getIPIfy } from "../redux/slice/getIPIfySlice";
import { productListSingle } from "../redux/slice/productListSingleSlice";
import { getSendDetails } from "../redux/slice/getSendDetailsSlice";
import Tracking from "../components/tracking";
import PhoneReportsRoutes from "../phoneReport/routes";
import AdminRoutes from "../admin/routes";
import { updateUserLanguage } from "../redux/slice/updateUserLanguageSlice";

const AppRoutes = (props) => {
  const {
    getAuthenticate,
    authenticate,
    getProductList,
    productList,
    callGclidCheck,
    getGclidDetails,
    callGetIPIfy,
    getProductListSingle,
    productListSingle,
    sendDetailsData,
    getSendDetails,
    callUpdateUserLanguage
  } = props;

  const cookies = new Cookies();
  const token = cookies.get("token");
  const location = useLocation();
  const navigate = useNavigate();
  const phoneReportHeaderLayout = [
    "premium-section",
    "premium-report",
    "access-report",
    "transaction",
    "my-account",
    "dashboard",
    "contact-us",
    "no-information-found",
    "faqs",
    "terms",
    "privacy-policy",
  ];
  const phoneReportFooterLayout = [
    "premium-section",
    "premium-report",
    "access-report",
    "my-account",
    "dashboard",
    "contact-us",
    "no-information-found",
    "faqs",
    "terms",
    "privacy-policy",
  ];
  const navArray = [
    "dash",
    "settings",
    "l",
    "users",
    "admin",
    "track",
    "sign-up",
    "currency",
    "trace",
    "step-tutorial",
    "location-preview-popup",
    "updating",
    "email-test",
    "location",
    ...phoneReportHeaderLayout,
    ...phoneReportFooterLayout,
  ];

  const blackPage = ["track", "sign-up"];
  const adminLayout = ["dashboard", "email-form"];
  const role = cookies.get("role");
  const userId = getUserId();
  const isAdmin = checkAdminLogin();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const { i18n } = useTranslation();
  const lang = cookies.get("lang");
  const [ip, setIp] = useState(null);
  const [gclidData, setgclid] = useState(null);
  const phoneReportsRoutes = PhoneReportsRoutes();
  const adminRoutes = AdminRoutes();
  const urlLang = location.pathname.split("/")[1]
  const userLang = authenticate.authenticateData?.data?.language
  const activeRoute =
  isAdmin && userId
    ? phoneReportsRoutes
    : isAdmin && !userId
    ? adminRoutes
    : token
    ? phoneReportsRoutes
    : null;

  useEffect(() => {
    getProductList();
    getProductListSingle();
    // eslint-disable-next-line
  }, []);
  const handleGetDetails = async () => {
    const result = await callGetIPIfy();

    if (result.type === "getIPIfy/fulfilled") {
      const ip = result.payload.ip;
      setIp(result.payload.ip);
      const sendDetailsResult = await getSendDetails(ip);
      if (sendDetailsResult.type === "getSendDetails/fulfilled") {
        const data = sendDetailsResult.payload.data;
        cookies.set("country_name", data.country_name, { path: "/" });
        cookies.set("postalCode", data.postal, { path: "/" });
        cookies.set("country_code", data.country_code.toUpperCase(), {
          path: "/",
        });
      }
    }
  };

  useEffect(() => {
    handleGetDetails();
  }, []);

  const getGclidDetailsData = async () => {
    const res = await getGclidDetails(ip);
    if (res.type === "getGclidDetails/fulfilled") {
      setgclid(res.payload.data);
    }
  };
  const handleSetParameter = async (key, value) => {
    const data = {
      ip_address: ip,
      key: key,
      value: value,
      signup: "off",
    };
    await callGclidCheck(data);
  };

  useEffect(() => {
    if (ip) {
      getGclidDetailsData();
    }
    // eslint-disable-next-line
  }, [ip]);

  useEffect(() => {
    if (ip) {
      if (searchNumber.gclid) {
        handleSetParameter("gclid", searchNumber.gclid);
      } else if (searchNumber.wbraid) {
        handleSetParameter("wbraid", searchNumber.wbraid);
      }
    }
    // eslint-disable-next-line
  }, [ip, gclidData]);

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    if (!lang) {
      cookies.set("lang", "en", {
        path: "/",
        sameSite: true,
      });
    }
    if (urlLang === "") {
      if (!token) {
        navigate(`/${lang ? lang : browserLanguage}/`);
      } else {
        navigate(`/${lang ? lang : browserLanguage}/dashboard`);
      }
    }
    // eslint-disable-next-line
  }, []);

  //  disable language for temp
  useEffect(() => {
    const browserLanguage = navigator.language || navigator.userLanguage; // Get browser language
    const updatedBrowserLanguage =
      browserLanguage === "en-US" ? "en" : browserLanguage; // Get browser language en-us will replace with "en"

    // filtered correct supported lang
    const langIcon = languages.find((item) => {
      return item.code === urlLang;
    });

    // filtered correct supported lang from  browser language
    const matchedLanguage = languages.find(
      (item) => item.code === updatedBrowserLanguage
    );
    if (langIcon) {
      i18n.changeLanguage(langIcon.code);
      cookies.set("lang", langIcon.code, {
        path: "/",
        sameSite: true,
      });
      cookies.set("langlogo", langIcon.flagCode);
    } else {
      if (matchedLanguage) {
        const langToUse = lang ? lang : matchedLanguage.code;
        cookies.set("lang", langToUse, {
          path: "/",
          sameSite: true,
        });
        if (!lang) {
          cookies.set("langlogo", matchedLanguage.flagCode);
        }
        i18n.changeLanguage(langToUse);
        navigate(
          location.pathname.split("/")[2]
            ? `/${langToUse}/${location.pathname.split("/")[2]}`
            : `/${langToUse}`
        );
      } else {
        cookies.set("lang", "en", {
          path: "/",
          sameSite: true,
        });
        cookies.set("langlogo", "gb");
        i18n.changeLanguage("en");
        const langToUse = lang ? lang : "en";
        navigate(
          location.pathname.split("/")[2]
            ? `/${langToUse}/${location.pathname.split("/")[2]}`
            : `/${langToUse}`
        );
      }
    }
    const pathnameParts = location.pathname.split("/");
    const currentLang = pathnameParts[1];

    // Apply RTL direction and unique class if the language is "ar" or "he"
    const rootElementRoot = document.getElementById("root");
    const rootElementAntd = document.querySelector(".ant-modal-root");

    //add class for long strings
    const trLanguages = ["bg", "uk", "pt", "pt-br", "es", "fr", "fi", "et"];
    if (trLanguages.includes(currentLang)) {
      rootElementRoot.classList.add("bts__language_wrap");
    } else {
      rootElementRoot.classList.remove("bts__language_wrap");
    }

    if (["ar", "he"].includes(currentLang)) {
      document.documentElement.setAttribute("dir", "rtl");
      if (rootElementRoot || rootElementAntd) {
        rootElementRoot.classList.add("theme_dir__rtl");
        rootElementAntd && rootElementAntd.classList.add("theme_dir__rtl");
      }
    } else {
      document.documentElement.setAttribute("dir", "ltr");
      if (rootElementRoot || rootElementAntd) {
        rootElementRoot.classList.remove("theme_dir__rtl");
        rootElementAntd && rootElementAntd.classList.remove("theme_dir__rtl");
      }
    }
    // When User Login And change language from Route
    if (token && urlLang !== userLang) {
      const data = {
        language: urlLang,
      }
       callUpdateUserLanguage(data)
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (searchNumber.c) {
      const signalTrail = location.pathname.includes("trace")
        ? productList
        : productListSingle;
      const trialProduct = signalTrail?.data.find(
        (item) => item.title === "trial"
      );
      const findPrice = trialProduct?.currency_options.find(
        (item) => item.parameter === searchNumber.c
      );
      if (findPrice) {
        cookies.set("currency", findPrice.parameter, { path: "/" });
      } else {
        if (sendDetailsData?.data?.currency) {
          // const currencyValue =
          //   sendDetailsData?.data?.currency in currency_data
          //     ? currency_data[sendDetailsData?.data?.currency]
          //     : "usd2";
          cookies.set("currency", "usd2", { path: "/" });
        }
      }
    } else {
      if (sendDetailsData?.data?.currency) {
        // const currencyValue =
        //   sendDetailsData?.data?.currency in currency_data
        //     ? currency_data[sendDetailsData?.data?.currency]
        //     : "usd2";
        cookies.set("currency", "usd2", { path: "/" });
      }
    }
    // eslint-disable-next-line
  }, [productList, productListSingle, sendDetailsData]);

  useEffect(() => {
    if (authenticate.authenticateData) {
      cookies.set("role", authenticate.authenticateData.data.role);
    }
    // eslint-disable-next-line
  }, [authenticate.authenticateData]);

  useEffect(() => {
    const isNavArray = navArray.includes(location.pathname.split("/")[2]);
    const isAdminLayout = adminLayout.includes(location.pathname.split("/")[3]);
    const isNavTokenArray = navTokenArray.includes(location.pathname.split("/")[2]);
    if (token) {
      if (location.pathname.split("/")[2] === "users") {
        if (role !== "admin") {
          navigate(`/${lang}/dash`);
        }
      } else if (isAdmin) {
        if (userId) {
          if (!isNavArray || isAdminLayout) {
            navigate(`/${lang}/dashboard`);
          }
        } else {
          if (!isAdminLayout) {
            navigate(`/${lang}/admin/dashboard`);
          }
        }
      } else {
        if (!isNavArray || isAdminLayout) {
          navigate(`/${lang}/dashboard`);
        }
      }
    } else if (isNavTokenArray) {
      const searchParams = location.search || "";
      const returnUrl = `${location.pathname}${searchParams}`;
      navigate(`/${lang}/login?returnUrl=${returnUrl}`);
    }
    // eslint-disable-next-line
  }, [token, location]);

useEffect(() => {
  if(token){
    getAuthenticate();
  }
 // eslint-disable-next-line
}, [token, userId])

  useEffect(() => {
    if (!blackPage.includes(location.pathname.split("/")[2])) {
      gtag("event", "page_view", {
        page_version: "w",
        send_to: "G-K38QXZZP92",
      });
    }
    document.body.style.overflow = "unset";
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/:lang/" element={<HomePage />}></Route>
        <Route path="/:lang/faq" element={<FAQ />}></Route>
        <Route path="/:lang/contact" element={<Contact />}></Route>
        <Route path="/:lang/pricing" element={<Pricing />}></Route>
        <Route path="/:lang/unsubscribe" element={<Unsubscribe />}></Route>
        <Route path="/:lang/login" element={<Login />}></Route>
        <Route path="/:lang/reset-pass" element={<ResetPassword />}></Route>
        <Route path="/:lang/sign-up" element={<SignUp />}></Route>
        <Route path="/:lang/signup" element={<SignUpSpreedly />}></Route>
        <Route
          path="/:lang/sign-up/subscription"
          element={<SignUp subscription={"standard"} />}
        ></Route>
        <Route
          path="/:lang/signup/subscription"
          element={<SignUpSpreedly subscription={"standard"} />}
        ></Route>
        <Route
          path="/:lang/track/sign-up"
          element={<SignUp page={"track"} />}
        ></Route>
        <Route
          path="/:lang/tracking/sign-up"
          element={<SignUp page={"tracking"} />}
        ></Route>
        <Route
          path="/:lang/trace/sign-up"
          element={<SignUpSpreedly page={"trace"} />}
        ></Route>
        <Route path="/:lang/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/:lang/terms" element={<TermConditions />}></Route>
        <Route path="/:lang/track" element={<Trace />}></Route>
        <Route path="/:lang/trace" element={<TracePage />}></Route>
        <Route path="/:lang/tracking" element={<Tracking />}></Route>
        {activeRoute }
        <Route path="/:lang/*" element={<NotFoundPage />}></Route>
      </Routes>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    authenticate: state.authenticate,
    productList: state.getProductList.productListData,
    productListSingle: state.getProductListSingle.productListSingleData,
    sendDetailsData: state.sendDetailsData.getSendDetailsData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAuthenticate: () => dispatch(authenticate()),
    getProductList: () => dispatch(productList()),
    getGclidDetails: (id) => dispatch(getGclidDetails(id)),
    callGclidCheck: (data) => dispatch(gclidCheck(data)),
    callGetIPIfy: () => dispatch(getIPIfy()),
    getProductListSingle: () => dispatch(productListSingle()),
    getSendDetails: (data) => dispatch(getSendDetails(data)),
    callUpdateUserLanguage: (data) => dispatch(updateUserLanguage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
