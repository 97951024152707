import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import MyAccountComponent from "./myAccount";
import { updateSetting } from "../../../redux/slice/updateSettingSlice";
import Notify from "../../../components/common/Notify/notify";
import Loader from "../../components/common/loader/loader";
import { authenticate } from "../../../redux/slice/authenticateSlice";
import { getPricingTitleList } from "../../redux/slice/getPricingTitleListSlice";
import { checkAdminLogin } from "../../utils/commonUtils";

const MyAccount = (props) => {
  const {authenticate, callUpdateSetting, getAuthenticate, callGetPricing} =
    props;

  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    address: "",
    country: "",
    city: "",
    zipcode: ""
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    address: "",
    country: "",
    city: "",
    zipcode: ""
  });

  useEffect(() => {
    callGetPricing();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (authenticate.authenticateData) {
      const data = authenticate.authenticateData.data;
      setDetails((prev) => ({
        ...prev,
        first_name: data.first_name,
        last_name: data.last_name,
        address: data.address,
        country: data.country,
        city: data.city,
        zipcode: data.zipcode
      }));
    }
  }, [authenticate.authenticateData]);

  const handleChangeDetails = (event) => {
    const {name, value} = event.target;
    const trimmedValue =
      typeof value === "string" ? value.replace(/^\s+/g, "") : value;
    setDetails((prev) => ({
      ...prev,
      [name]: trimmedValue
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: ""
    }));
  };
  const handleChangeCountry = (value) => {
    const trimmedValue =
      typeof value === "string" ? value.replace(/^\s+/g, "") : value;
    setDetails((prev) => ({
      ...prev,
      country: trimmedValue
    }));
    setErrors((prev) => ({
      ...prev,
      country: ""
    }));
  };

  const handleUpdateDetails = async () => {
    if (checkAdminLogin()) {
      return;
    }
    const validationErrors = {};
    const validateField = (fieldName, errorMessage) => {
      if (!details[fieldName]?.trim()) {
        validationErrors[fieldName] = errorMessage;
      }
    };

    // Perform validation
    validateField("first_name", t("PR_FIRST_NAME"));
    validateField("last_name", t("PR_LAST_NAME"));
    validateField("address", t("PR_STREET_ADDRESS"));
    validateField("country", t("PR_ENTER_COUNTRY"));
    validateField("city", t("PR_CITY"));
    validateField("zipcode", t("PR_POSTAL_CODE"));

    // Check for validation errors
    if (Object.keys(validationErrors).length > 0) {
      setErrors((prev) => ({
        ...prev,
        ...validationErrors
      }));
      return;
    }

    setLoading(true);

    try {
      const result = await callUpdateSetting(details);

      if (result.type === "updateSetting/fulfilled") {
        Notify("success", t("NOTIFY_UPDATE_SETTINGS"), "");
      } else {
        Notify("error", result.error.message, "");
      }

      // Call to fetch updated authentication data
      getAuthenticate();
    } catch (error) {
      Notify("error", error.message, "");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <MyAccountComponent
        handleChangeDetails={handleChangeDetails}
        details={details}
        handleUpdateDetails={handleUpdateDetails}
        loading={loading}
        errors={errors}
        authenticate={authenticate}
        handleChangeCountry={handleChangeCountry}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    authenticate: state.authenticate,
    unsubscribeResult: state.unsubscribe
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callUpdateSetting: (data) => dispatch(updateSetting(data)),
    getAuthenticate: () => dispatch(authenticate()),
    callGetPricing: (data) => dispatch(getPricingTitleList(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
