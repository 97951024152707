import React, { lazy, Suspense, useEffect } from "react";
import { Route, useLocation } from "react-router-dom";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {stripeLoadScript} from "../environment";
import {bigLanguages} from "./utils/commonUtils";
const AccessReport = lazy(() => import("./pages/accessReport"));
const MyAccount = lazy(() => import("./pages/myAccount"));
const PhoneReportDashboard = lazy(() => import("./pages/PhoneReportDashboard"));
const ContactUs = lazy(() => import("./pages/contactUs"));
const FaqComponent = lazy(() => import("./pages/faq/faqComponent.jsx"));
const LocationTrack = lazy(() => import("./pages/locationTrack"));

const PhoneReportsRoutes = () => {
  const stripePromise = loadStripe(stripeLoadScript);
  const location = useLocation();

  useEffect(() => {
    const pathnameParts = location.pathname.split("/");
    const currentLang = pathnameParts[1];
    // Apply RTL direction and unique class if the language is "ar" or "he"
    const rootElementRoot = document.getElementById("root");
    if (bigLanguages.includes(currentLang)) {
      rootElementRoot.classList.add("high__lang_wrapper");
    } else {
      rootElementRoot.classList.remove("high__lang_wrapper");
    }
  }, [location.pathname]);

  return (
    <>
      <Route
        path="/:lang/access-report"
        element={
          <Elements stripe={stripePromise}>
            <Suspense fallback={<div>Loading...</div>}>
              <AccessReport />
            </Suspense>
          </Elements>
        }
      />
      <Route 
        path="/:lang/my-account"
        element={
          <Elements stripe={stripePromise}>
            <Suspense fallback={<div>Loading...</div>}>
              <MyAccount />
            </Suspense>
          </Elements>
        }
      />
      <Route 
        path="/:lang/dashboard" 
        element = {
          <Suspense fallback={<div>Loading...</div>}>
            <PhoneReportDashboard />
          </Suspense>
        }
      />
      <Route 
        path="/:lang/contact-us" 
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <ContactUs />
          </Suspense>
          }
      />
      <Route 
        path="/:lang/faqs" 
        element={
            <Suspense fallback={<div>Loading...</div>}>
              <FaqComponent />
            </Suspense>
        } />
      <Route 
        path="/:lang/location" 
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <LocationTrack />
          </Suspense>
        }
      />
    </>
  );
};

export default PhoneReportsRoutes;
