import { useEffect, useState } from "react";
import { Form, Input, Select, Button, Row, Col} from "antd";
import { connect } from "react-redux";
import { t } from "i18next";
import "./emailForm.scss";
import { sendEmail } from "../../../phoneReport/redux/slice/admin/sendEmailSlice";
import {adminConfig}  from "../../../phoneReport/redux/slice/admin/getAdminConfig"
import Notify from "../../../components/common/Notify/notify";

const EmailForm = ({callSendEmail, getAdminConfig}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [sourceData , setSourceData] = useState([{
    languages : [],
    sites : [],
    subscription: [],
    templates : [],
  }])

  useEffect(() => {
    getAdminConfigData()
  }, []);

const getAdminConfigData = async () => {
  setIsLoading(true);
  try {
    const result = await getAdminConfig();
    if (result.type === "adminConfig/fulfilled") {
      const adminConfigData = result?.payload?.data || {};
      const processedData = {
        languages:
          adminConfigData?.languages?.map((lang) => ({
            label: lang,
            value: lang,
          })) || [],
        sites:
          adminConfigData?.sites?.map((site) => ({
            label: site?.name,
            value: site?.handle,
          })) || [],
        subscription:
          adminConfigData?.subscription_names?.map((subscription) => ({
            label: subscription?.label,
            value: subscription?.value,
          })) || [],
        templates:
          adminConfigData?.templates?.map((template) => ({
            label: template?.label,
            value: template?.value,
          })) || [],
      };
      setSourceData(processedData);
    } else {
      Notify("error", result.error?.message || "An error occurred.", "");
    }
  } catch (error) {
    Notify("error", error.message || "An unexpected error occurred.", "");
  } finally {
    setIsLoading(false);
  }
};

const handleSendEmail = async (values) => {
    const { email, website, language, sub_name, template } = values;
    const payload = {
      email: email.toLowerCase(),
      website,
      language,
      template,
      sub_name: ["renew_subscription", "subscription_canceled"].includes(template) ? sub_name : undefined,
    };
    try {
      setIsLoading(true);
      const result = await callSendEmail(payload);
      console.log(result);
      if (result.type === "sendEmail/fulfilled") {
        Notify("success", t("PR_NOTIFY_EMAIL_SEND_SUCCESS"), "");
        form.resetFields();
      } else {
        Notify("error", result.error.message, "");
      }
    } catch (error) {
      Notify("error", t("PR_NOTIFY_EMAIL_SEND_FAILED"), "");
    } finally {
      setIsLoading(false);
    }
};

  return (
    <div className="email-form-container">
        <Form
          form={form}
          name="emailForm"
          onFinish={handleSendEmail}
          layout="vertical"
          className="email-custom-form"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!"
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!"
                  }
                ]}
              >
                <Input
                  placeholder="Enter your email"
                  className="email-custom-input"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="website"
                label="Website"
                rules={[{ required: true, message: "Please select a website!" }]}
              >
                <Select placeholder="Select a website">
                  {sourceData?.sites?.map((site) => (
                    <Option key={site?.value} value={site?.value}>
                      {site?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="language"
                label="Language"
                rules={[{ required: true, message: "Please select a language!" }]}
              >
                <Select placeholder="Select a language" popupMatchSelectWidth={false}>
                  {sourceData?.languages?.map((lang) => (
                    <Option key={lang?.label} value={lang?.value}>
                      {lang?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="sub_name"
                label="Subscription Name"
                rules={[
                  {
                    required: true,
                    message: "Please select a subscription name!"
                  }
                ]}
              >
                <Select placeholder="Select a subscription name">
                  {sourceData?.subscription?.map((subscription) => (
                    <Option key={subscription?.value} value={subscription?.value}>
                      {subscription?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="template"
                label="Email Template"
                rules={[
                  {
                    required: true,
                    message: "Please select an email template!"
                  }
                ]}
              >
                <Select placeholder="Select an email template">
                  {sourceData?.templates?.map((template) => (
                    <Option key={template?.value} value={template?.value}>
                      {template?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isLoading}
                  className="email-form-submit-button"
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  languages: state.getLanguages,
});

const mapDispatchToProps = (dispatch) => ({
  callSendEmail: (data) => dispatch(sendEmail(data)),
  getAdminConfig: (data) => dispatch(adminConfig(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailForm);